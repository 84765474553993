/* eslint-disable */
import * as mutation from './mutations-types';
import defaultState from './state';

export default {
    [mutation.RESET](state) {
        const s = defaultState;
        Object.keys(s).forEach((key) => {
            state[key] = s[key];
        });
    },

    [mutation.CLEAN_ACCESS_TOKEN](state) {
        state.access_token = null;
    },

    [mutation.UPDATE_ACCESS_TOKEN](state, token) {
        state.access_token = token;
    }
};
