export default {
  getDeliveryMode(state) {
    return state.deliveryMode;
  },
  getAddress(state) {
    return index => state.destinations[index];
  },
  getAdditionalOptions(state) {
    return state.additionalOptions;
  },
  getDefaultAddressParsed(state, getters, rootState, rootGetters) {
    const defaultAddress = rootGetters["user/getDefaultAddress"][0];
    const schedule = 1;
    const additional = { deliveryProof: true };
    return defaultAddress
      ? {
          index: 0,
          data: {
            id: defaultAddress.id,
            address: {
              address: defaultAddress.address,
              position: defaultAddress.position
            },
            phone: defaultAddress.phone || "",
            name: defaultAddress.name || "",
            email: defaultAddress.email || "",
            information: defaultAddress.information || "",
            schedule,
            additional
          }
        }
      : null;
  },
  getDestinationOrigin(state) {
    return state.destinations[0].data;
  },
  isFormValid(state) {
    const data = state.destinations[0].data;
    const isAddressValid =
      Boolean(data.address.address) && Boolean(data.address.position);
    const isNameValid = Boolean(data.name);
    const isPhoneValid = Boolean(data.phone);

    return isAddressValid && isNameValid && isPhoneValid;
  }
};
