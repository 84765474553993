import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("Services");

const Services = Object.assign(
  {},
  {
    createMultipleInvoices(data) {
      return loopback.post("/Services/createMultipleInvoices", data);
    },

    createInvoice(data) {
      return loopback.post("Services/createInvoice", data);
    },

    getBillableListing({ clientId, filter }) {
      return loopback.get(`/Services/billable?clientId=${clientId}`, {
        params: { filter }
      });
    },

    getActiveServices() {
      return loopback.get("/Services/getActiveServices");
    },

    getDetails(id) {
      return loopback.get(`/Services/${id}/details`);
    },

    getStatusById(id) {
      return loopback.get(`/Services/getStatusById/?serviceId=${id}`);
    },

    belongsDestinationEco(data) {
      const destinations = {};
      destinations.addresses = [];
      // eslint-disable-next-line
        for (const destination of data) {
        destinations.addresses.push(destination.data.position);
      }
      return loopback.post("/Services/belongsDestinationEco", {
        destination: destinations
      });
    },

    listing({ filter, where }) {
      return loopback.get("/Services/listing", {
        params: { filter, where }
      });
    },

    request(service) {
      return loopback.post("/Services/request", { service });
    },

    updateService(service) {
      return loopback.post("/Services/editService", { service });
    },

    serviceRate(data) {
      return loopback.post("/Services/serviceRate", data);
    },

    cancel(data) {
      return loopback.post(`/Services/${data.id}/cancel`, data);
    },

    trackingInfo(id) {
      return loopback.get(`Services/${id}/trackingInfo`);
    },

    pay({ id, payment }) {
      return loopback.post(`/Services/${id}/pay`, payment);
    },

    serviceEstimation(data) {
      return loopback.post("/Services/serviceEstimation", data);
    }
  },
  modelObject
);

export default Services;
