const TODAY = "Hoy";
const YESTERDAY = "Ayer";
const THIS_WEEK = "Esta Semana";
const LAST_WEEK = "Semana Pasada";
const THIS_MONTH = "Este Mes";
const LAST_MONTH = "Mes Pasado";
const THIS_YEAR = "Este Año";
const CUSTOM = "Personalizado";
const TOMORROW = "Mañana";

const NUM_TODAY = 0;
const NUM_YESTERDAY = 1;
const NUM_THIS_WEEK = 2;
const NUM_LAST_WEEK = 3;
const NUM_THIS_MONTH = 4;
const NUM_LAST_MONTH = 5;
const NUM_THIS_YEAR = 6;
const NUM_CUSTOM = 7;
const NUM_TOMORROW = 8;

const options = [
  { value: NUM_TODAY, text: TODAY },
  { value: NUM_YESTERDAY, text: YESTERDAY },
  { value: NUM_THIS_WEEK, text: THIS_WEEK },
  { value: NUM_LAST_WEEK, text: LAST_WEEK },
  { value: NUM_THIS_MONTH, text: THIS_MONTH },
  { value: NUM_LAST_MONTH, text: LAST_MONTH },
  { value: NUM_THIS_YEAR, text: THIS_YEAR },
  { value: NUM_CUSTOM, text: CUSTOM }
];

const simplifiedOptions = [
  { value: NUM_THIS_WEEK, text: THIS_WEEK },
  { value: NUM_THIS_MONTH, text: THIS_MONTH },
  { value: NUM_LAST_MONTH, text: LAST_MONTH },
  { value: NUM_THIS_YEAR, text: THIS_YEAR },
  { value: NUM_CUSTOM, text: CUSTOM }
];

const get = {
  [NUM_TODAY]: TODAY,
  [NUM_YESTERDAY]: YESTERDAY,
  [NUM_THIS_WEEK]: THIS_WEEK,
  [NUM_LAST_WEEK]: LAST_WEEK,
  [NUM_THIS_MONTH]: THIS_MONTH,
  [NUM_LAST_MONTH]: LAST_MONTH,
  [NUM_THIS_YEAR]: THIS_YEAR,
  [NUM_CUSTOM]: CUSTOM,
  [NUM_TOMORROW]: TOMORROW
};

export default {
  options,
  simplifiedOptions,
  get,
  TODAY,
  YESTERDAY,
  THIS_WEEK,
  LAST_WEEK,
  THIS_MONTH,
  LAST_MONTH,
  THIS_YEAR,
  CUSTOM,
  TOMORROW,
  NUM_TODAY,
  NUM_YESTERDAY,
  NUM_THIS_WEEK,
  NUM_LAST_WEEK,
  NUM_THIS_MONTH,
  NUM_LAST_MONTH,
  NUM_THIS_YEAR,
  NUM_CUSTOM,
  NUM_TOMORROW
};
