<template>
  <button
    :class="[
      'z-button',
      { disabled },
      variantClass,
      textColorClass,
      { 'z-loading': loading },
      { expanded: expanded },
      { 'size-sm': size === 'sm' },
      { 'size-md': size === 'md' },
      { 'size-lg': size === 'lg' },
      { 'fit-normal': fit === 'normal' },
      { 'fit-auto': fit === 'auto' }
    ]"
    :disabled="disabled"
    :type="type"
    @click="$emit('click')"
  >
    <div v-show="loading" class="loading-spinner"></div>
    <div v-show="!loading">
      <slot></slot>
    </div>
  </button>
</template>

<script>
/**
 * Zubut styled button.
 */
export default {
  name: "ZButton",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: false
    },
    fit: {
      type: String,
      default: "normal",
      validator: value => ["normal", "auto"].indexOf(value) !== -1
    },
    loading: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "md",
      validator: value => ["sm", "md", "lg"].indexOf(value) !== -1
    },
    type: {
      type: String,
      default: "button",
      validator: value => ["button", "submit", "reset"].indexOf(value) !== -1
    },
    variant: {
      type: String,
      default: "primary",
      validator: value =>
        ["primary", "secondary", "link", "success", "primary-alt"].indexOf(
          value
        ) !== -1
    },
    textColor: {
      type: String,
      default: "",
      validator: value => ["primary", ""].indexOf(value) !== -1
    }
  },

  computed: {
    variantClass() {
      return `z-button-${this.variant}`;
    },
    textColorClass() {
      if (this.textColor) {
        return `text-${this.textColor}`;
      }
      return "";
    }
  }
};
</script>

<style lang="scss">
@import "../styles/variables/colors.scss";

.z-button {
  display: inline;
  border: none;
  border-radius: 6px;
  font-size: 0.875em;
  font-weight: 500;
  line-height: 16px;

  &.expanded {
    width: 100%;
  }

  &.size-sm {
    font-size: 0.875em;
    padding: 0.5em 1em;
  }

  &.size-md {
    padding: 0.8em 1em;
  }

  &.size-lg {
    padding: 1em 2.5em;
  }

  &.fit-normal {
    min-width: 79px;
  }

  &.fit-auto {
    width: auto;
  }

  .loading-spinner {
    position: relative;
    margin-bottom: -4px;
    height: 16px;
    width: 16px;
    color: $white;
    display: inline-block;
    border: 2px solid;
    border-radius: 50%;
    border-top-color: transparent;
    animation: rotate 1s linear infinite;
  }
}

.z-button.z-button-primary {
  background: var(--primary);
  color: $white;
  cursor: pointer;

  &.disabled {
    background: var(--btn-primary-disabled);
    color: $white;
    cursor: default;

    &:hover {
      background: var(--btn-primary-disabled);
    }
  }

  &:hover {
    background: var(--btn-primary-hover);
  }
}

.z-button.disabled.z-loading .loading-spinner {
  color: $zubut-blue;
}

.z-button.z-button-secondary {
  background: $ghost-white;
  color: $charcoal;
  cursor: pointer;

  &.disabled {
    background: $ghost-white;
    color: $dark-gray;
    cursor: default;

    &:hover {
      background: $ghost-white;
    }
  }

  &:hover {
    background: $gainsboro;
  }
}

.z-button.z-button-link {
  background: transparent;
  color: var(--btn-primary-hover);
  cursor: pointer;
  padding: 0px;
  font-weight: 100;
  font-size: 1em;
  &.disabled {
    background: $ghost-white;
    color: $dark-gray;
    cursor: default;
    &:hover {
      background: var(--btn-primary-hover);
    }
  }
  &:hover {
    text-decoration: underline;
  }
}

.z-button.z-button-success {
  background: $free-aquamarine;
  color: $white;
  cursor: pointer;
  padding: 0;
  font-weight: 100;
  font-size: 1em;
  &.disabled {
    background: $ghost-white;
    color: $dark-gray;
    cursor: default;
    &:hover {
      background: var(--btn-primary-hover);
    }
  }
  &:hover {
    text-decoration: underline;
  }
}

.z-button.z-button-primary-alt {
  background-color: var(--primary-bg);
  color: var(--primary);
  cursor: pointer;

  &.disabled {
    background: transparent;
    color: $dark-gray;
  }

  &:hover {
    background-color: var(--primary-bg);
    text-decoration: none;
  }
}

.z-button {
  &.text-primary {
    color: $zubut-blue;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
