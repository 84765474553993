import Vue from "vue";
import * as mutation from "./mutations-types";
import defaultState from "./state";

export default {
  [mutation.RESET](state) {
    const s = defaultState;
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },

  [mutation.UPDATE_USER](state, user) {
    Object.keys(user).forEach(key => {
      state[key] = user[key];
    });
  },

  [mutation.UPDATE_BILLING_DATA](state, billing) {
    state.billingData = billing;
  },

  [mutation.ADD_CREDIT_CARD](state, creditCard) {
    state.creditCards = [...state.creditCards, { ...creditCard }];
  },

  [mutation.DELETE_CREDIT_CARD](state, creditCardId) {
    state.creditCards = state.creditCards.filter(
      item => item.id !== creditCardId
    );
  },

  [mutation.DELETE_ALL_CREDIT_CARDS](state) {
    state.creditCards = [];
  },

  [mutation.CLEAR_DEFAULT_CREDIT_CARD](state) {
    state.creditCards = state.creditCards.map(card => ({
      ...card,
      default: false
    }));
  },

  [mutation.UPDATE_DEFAULT_CREDIT_CARD](state, creditCardId) {
    const defaultCard = state.creditCards.find(
      card => card.id === creditCardId
    );
    defaultCard.default = true;
    state.creditCards = state.creditCards.map(card =>
      card.id !== creditCardId ? card : defaultCard
    );
  },

  [mutation.ADD_ADDRESS](state, address) {
    state.addresses.push(address);
  },

  [mutation.UPDATE_ADDRESS](state, payload) {
    const updated = state.addresses.map((address, index) => {
      if (payload.index === index) {
        return {
          id: payload.address.id,
          address: payload.address.address,
          company: payload.address.company,
          defaultOrigin: payload.address.defaultOrigin,
          information: payload.address.information,
          name: payload.address.name,
          phone: payload.address.phone,
          position: payload.address.position,
          clientId: payload.address.clientId
        };
      }
      return address;
    });
    state.addresses = updated;
  },

  [mutation.DELETE_ADDRESS](state, index) {
    state.addresses.splice(index, 1);
  },

  [mutation.DELETE_ALL_ADDRESS](state) {
    state.addresses = [];
  },

  [mutation.UPDATE_DEFAULT_ADDRESS](state, index) {
    Vue.set(state.addresses[index], "defaultOrigin", true);
  },

  [mutation.CLEAR_DEFAULT_ADDRESS](state) {
    state.addresses.forEach((_, index, addresses) => {
      Vue.set(addresses[index], "defaultOrigin", false);
    });
  },

  [mutation.UPDATE_WALLET](state, wallet) {
    state.wallet = { ...wallet };
  },

  [mutation.DELETE_WALLET_PENDING](state) {
    state.wallet = {
      ...state.wallet,
      pending: false,
      clabe: "",
      reference: "",
      pendingAmount: 0
    };
  },

  [mutation.UPDATE_SESSION_POSITION](state, newPosition) {
    state.sessionPosition = newPosition;
  },

  [mutation.UPDATE_USER_ADDITIONAL](state, additional) {
    state.additional = { ...additional };
  },

  [mutation.UPDATE_ENABLED_SERVICES](state, enabledServices) {
    state.enabledServices = [...enabledServices];
  }
};
