const defaultState = {
  deliveries: [],
  deliveriesTotal: 0,
  deliveriesFilter: {
    text: null,
    clientId: null,
    status: null,
    deliveryType: null,
    sortBy: "createdAt",
    sortOrder: "desc"
  },
  hasUpdates: false
};

export default defaultState;
