import Vue from "vue";
import VeeValidate, { Validator } from "vee-validate";
import VeeValidateEs from "vee-validate/dist/locale/es";

Vue.use(VeeValidate, {
  fieldsBagName: "veeFields",
  errorBagName: "veeErrors"
});

Validator.localize("es", VeeValidateEs);
