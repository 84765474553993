import * as mutation from "./mutations-types";
import defaultState from "./state";
import _cloneDeep from "lodash/cloneDeep";

export default {
  [mutation.RESET](state) {
    const s = defaultState;
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },

  [mutation.UPDATE_IS_RECONNECTING](state, isReconnecting) {
    state.isReconnecting = isReconnecting;
  },

  [mutation.UPDATE_CONNECT_STATE](state, connect) {
    state.isConnected = connect;
  }
};
