const NEW = "Nuevo";
const PENDING = "Pendiente";
const ACTIVE = "Activo";
const BLOCKED = "Bloqueado";

const NUM_NEW = 0;
const NUM_PENDING = 1;
const NUM_ACTIVE = 2;
const NUM_BLOCKED = 3;

const get = {};
get[NUM_NEW] = NEW;
get[NUM_PENDING] = PENDING;
get[NUM_ACTIVE] = ACTIVE;
get[NUM_BLOCKED] = BLOCKED;

export default {
  get,
  NEW,
  PENDING,
  ACTIVE,
  BLOCKED,
  NUM_NEW,
  NUM_PENDING,
  NUM_ACTIVE,
  NUM_BLOCKED
};
