import { captureError } from "@/plugins/error-tracking";
import Cities from "@/services/cities";
import * as mutation from "./mutations-types";

export function getCities({ state, commit }) {
  return new Promise((resolve, reject) => {
    if (!state.cities.length) {
      Cities.get({})
        .then(cities => {
          commit(mutation.SET_CITIES, cities);
          if (cities.length && cities[0]) {
            commit(mutation.SET_SELECTED_CITY, cities[0].id);
          }
          resolve(cities);
        })
        .catch(err => {
          captureError(err);
          reject(err);
        });
    }
  });
}
