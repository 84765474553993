import Vue from "vue";
import Vuex from "vuex";

import { userSession } from "@/storage";
import auth from "./modules/auth";
import branches from "./modules/branches";
import cities from "./modules/cities";
import dashboard from "./modules/dashboard";
import express from "./modules/express";
import parcel from "./modules/parcel";
import request from "./modules/request";
import user from "./modules/user";
import notification from "./modules/notification";
import deliveries from "./modules/deliveries";
import services from "./modules/services";
import rents from "./modules/rents";
import wallets from "./modules/wallets";
import csv from "./modules/csv";
import utils from "./modules/utils";
import branch from "./modules/branch";
import DateConstants from "@/constants/filters/date";
import changeTheme from "@/utils/changeTheme";
import LocationUtils from "@zubut/common/src/utils/location";

Vue.use(Vuex);

function initialState() {
  return {
    notificationDialog: {
      dateFilter: DateConstants.NUM_TODAY,
      customDateFilter: {
        from: null,
        until: null
      }
    },
    activeServicesDialog: {
      menuOption: 0,
      loading: true,
      items: [],
      show: true
    },
    windowWidth: window.innerWidth,
    displayNotFound: false,
    region: ""
  };
}

export default new Vuex.Store({
  state: initialState,

  modules: {
    auth,
    branches,
    cities,
    dashboard,
    express,
    parcel,
    request,
    user,
    notification,
    services,
    deliveries,
    rents,
    wallets,
    csv,
    utils,
    branch
  },

  mutations: {
    reset(state) {
      const s = initialState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },

    initialiseStore(state) {
      const rehydrate = userSession.get();
      if (rehydrate !== null) {
        const stateRehydrated = {};
        Object.assign(stateRehydrated, state);
        stateRehydrated.user = JSON.parse(rehydrate);
        stateRehydrated.user.creditCards = [];
        stateRehydrated.user.wallet = {
          id: "",
          pending: "",
          isWallet: true,
          brand: "Wallet",
          amount: 0,
          clabeReferente: ""
        };
        stateRehydrated.user.addresses = [];
        this.replaceState(Object.assign(state, stateRehydrated));
      }
    },

    setNotificationDialog(state, notificationDialog) {
      state.notificationDialog = Object.assign({}, notificationDialog);
    },

    setActiveServicesDialog(state, activeServicesDialog) {
      state.activeServicesDialog = Object.assign({}, activeServicesDialog);
    },

    setWindowWidth(state, width) {
      state.windowWidth = width;
    },

    setDisplayNotFound(state, value) {
      state.displayNotFound = value;
    },

    setRegion(state, value) {
      state.region = value;
    }
  },

  getters: {
    isZubut(state) {
      return state.region === "MX";
    },
    companyName(_, getters) {
      return getters.isZubut ? "Zubut" : "Mensajeros Urbanos";
    }
  },

  actions: {
    getCountry({ commit }) {
      return new Promise(resolve => {
        let countryCode = "";
        if (
          window.location.href.includes("mu-staging.zubut.com") ||
          window.location.href.includes("mu.zubut.com")
        ) {
          countryCode = "CO";
        } else {
          countryCode = LocationUtils.getCountry().code;
        }
        commit("setRegion", countryCode);

        changeTheme(countryCode);

        resolve(countryCode);
      });
    }
  }
});
