import DeliveryMode from "@zubut/common/src/constants/parcels/deliveryMode";

const defaultDestination = {
  address: "",
  name: "",
  email: "",
  phone: "",
  information: "",
  schedule: 0
};

const defaultState = {
  deliveryMode: DeliveryMode.NUM_SAME_DAY,
  destinations: [
    {
      index: 0,
      data: defaultDestination
    }
  ],
  additionalOptions: {
    deliveryProof: true
  }
};

export default defaultState;
