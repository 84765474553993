import VehicleType from "@zubut/common/src/constants/vehicles/type";

const defaultDestination = {
  address: "",
  name: "",
  phone: "",
  information: "",
  id: null
};

const defaultState = {
  destinations: [
    {
      index: 0,
      data: defaultDestination
    },
    {
      index: 1,
      data: defaultDestination
    }
  ],
  additionalOptions: {
    signature: false,
    driverReturn: false,
    restrictedItems: false,
    scheduled: null
  },
  vehicleType: VehicleType.NUM_MOTORCYCLE,
  paymentMethod: "wallet",
  cardId: null,
  rates: {
    km: null,
    minutes: 0,
    cost: 0,
    currency: ""
  },
  vehicleRates: {
    bikeKMCost: -1,
    carKMCost: -1,
    ecoKMCost: -1
  }
};

export default defaultState;
