<template>
  <div class="lds-css ng-scope">
    <div style="width:100%;height:100%" class="lds-rolling">
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingSpinner"
};
</script>

<style type="text/css" scoped>
@keyframes lds-rolling {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes lds-rolling {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.lds-rolling {
  position: relative;
  margin: auto;
}

.lds-rolling div,
.lds-rolling div:after {
  position: absolute;
  width: 50px;
  height: 50px;
  border: 5px solid var(--primary);
  border-top-color: transparent;
  border-radius: 50%;
}

.lds-rolling div {
  -webkit-animation: lds-rolling 1s linear infinite;
  animation: lds-rolling 1s linear infinite;
  top: 25px;
  left: 58px;
}

.lds-rolling div:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.lds-rolling {
  width: 50px !important;
  height: 50px !important;
  -webkit-transform: translate(-25px, -25px) scale(0.4) translate(25px, 25px);
  transform: translate(-25px, -25px) scale(0.4) translate(25px, 25px);
}
</style>
