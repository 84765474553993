const ADDRESSES = "direcciones";
const BILLING = "facturacion";
const BRANCHES = "sucursales";
const CONFIRMATION = "confirmation";
const CREATE_BRANCH = "crear-sucursal";
const CREATE_DELIVERY = "crear-entrega";
const DASHBOARD = "panel-control";
const DELIVERIES = "entregas";
const HELP = "terminos-condiciones";
const HISTORY = "historial";
const INVOICES = "pagos-facturas";
const LOGIN = "inicio-sesion";
const PAYMENT_METHODS = "metodos-pago";
const PROFILE = "perfil";
const RECONNECT = "reconectar";
const RENTS = "renta-hora";
const RESERVATIONS = "reservaciones";
const RETRIEVE_PASSWORD = "recuperar-contrasena";
const SERVICES = "servicios";
const STATISTICS = "estadisticas";
const TRACKING = "tracking";

export default {
  ADDRESSES,
  BILLING,
  BRANCHES,
  CONFIRMATION,
  CREATE_BRANCH,
  CREATE_DELIVERY,
  DASHBOARD,
  DELIVERIES,
  HELP,
  HISTORY,
  INVOICES,
  LOGIN,
  PAYMENT_METHODS,
  PROFILE,
  RECONNECT,
  RENTS,
  RESERVATIONS,
  RETRIEVE_PASSWORD,
  SERVICES,
  STATISTICS,
  TRACKING
};
