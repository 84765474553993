export function getNotificationsFilterdByText(state) {
  return function(text) {
    return state.notifications.filter(
      item =>
        item &&
        text &&
        item.message.toLowerCase().indexOf(text.toLowerCase()) !== -1
    );
  };
}

export function notifications(state) {
  return state.notifications;
}
