import * as mutation from "./mutations-types";

export default {
  [mutation.SET_DELIVERIES](state, deliveries) {
    state.deliveries = [...deliveries];
  },

  [mutation.UPDATE_DELIVERIES_FILTER_TEXT](state, text) {
    state.deliveriesFilter.text = text;
  },

  [mutation.SET_FILTER_CLIENT](state, clientId) {
    state.deliveriesFilter.clientId = clientId;
  },

  [mutation.SET_FILTER_DELIVERY_TYPE](state, type) {
    state.deliveriesFilter.deliveryType = type;
  },

  [mutation.SET_FILTER_SORT_ORDER](state, sortOrder) {
    state.deliveriesFilter.sortOrder = sortOrder;
  },

  [mutation.SET_FILTER_STATUS](state, status) {
    state.deliveriesFilter.status = status;
  },

  [mutation.UPDATE_DELIVERIES_TOTAL](state, total) {
    state.deliveriesTotal = total;
  },

  [mutation.TOGGLE_UPDATE_INDICATOR](state, hasUpdates) {
    state.hasUpdates = hasUpdates;
  }
};
