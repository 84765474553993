import Clients from "@/services/clients";
import store from "@/store/index";
import router from "@/router";
import { captureError } from "@/plugins/error-tracking";

const createSocketInstance = () => require('engine.io-client')(process.env.VUE_APP_API_URL); // eslint-disable-line

let socket = null;

let clientClosed = false;

const init = () => {
  console.log("init");

  if (!socket) {
    socket = createSocketInstance();
  }

  socket.on("open", () => {
    const request = {
      userId: Clients.getCurrentId(),
      socketEvent: "connect"
    };

    if (request.userId) {
      request.accessToken = JSON.parse(
        localStorage.getItem("loopback-token")
      ).id;

      if (store.state.user) {
        const { name } = store.state.user;
        request.data = { name };
      }
      const requestData = JSON.stringify(request);

      socket.send(requestData);
    }

    socket.on("message", data => {
      const jsonData = JSON.parse(data);
      if (process.env.NODE_ENV === "development") {
        console.log("message:event", jsonData.socketEvent);
        console.log("message:data", jsonData.data);
      }
      switch (jsonData.socketEvent) {
        case "connected":
          store.dispatch("request/updateConnectState", true);
          break;
        case "newUserNotification":
        case "updateNotifications":
          store.dispatch(`notification/${jsonData.socketEvent}`, jsonData.data);
          break;
        case "updateWallet":
          store.dispatch(`user/getWallet`);
          break;
        case "clientClosed":
          clientClosed = true;
          break;
        case "newAdminNotification":
          break;
        default:
          store.dispatch(`services/${jsonData.socketEvent}`, jsonData.data);
          break;
      }
    });

    socket.on("close", reason => {
      store.dispatch("request/updateConnectState", false);
      switch (reason) {
        case "transport close":
          console.log("transport close");
          if (!clientClosed) {
            store
              .dispatch("request/reconnectServer")
              .then(() => {
                console.log("reload");
                router.go();
              })
              .catch(error => {
                captureError(error);
                router.push({ name: "reconnect" });
              });
          }
          break;
        default:
          break;
      }
      socket = null;
      clientClosed = false;
    });
  });
};

const cleanSocket = () => {
  if (socket) {
    socket.close();
  }
  socket = null;
  clientClosed = false;
};

export default {
  init,
  cleanSocket
};
