import { captureError } from "@/plugins/error-tracking";
import CSVContainers from "@/services/csvContainer";

export function getServices({}, where) {
  return new Promise((resolve, reject) => {
    CSVContainers.generateServices(where)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}
