import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faCalendarAlt,
  faCalendarDay,
  faCheck,
  faChevronDown,
  faCircle,
  faCreditCard,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFilter,
  faInfoCircle,
  faMapMarkerAlt,
  faMoon,
  faPencilAlt,
  faQuestionCircle,
  faSearch,
  faSlidersH,
  faStar,
  faStoreAlt,
  faSun,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* Custom icons */
import {
  faApprove,
  faAdd,
  faArrowDropDown,
  faBellRing,
  faBusiness,
  faDownload,
  faFavoriteAddress,
  faHelmet,
  faHelp,
  faHexagonBullet,
  faHistory,
  faIncidence,
  faInvoices,
  faLogOut,
  faOptions,
  faPhoneCall,
  faPrint,
  faRestaurant,
  faSort,
  faStatistics,
  faWhatsApp,
  faSignFile,
  faZubut,
  faZubutBike,
  faZubutBikeActive,
  faZubutCar,
  faZubutMotorcycle,
  faReload,
  faCopyDestination
} from "./icons";

library.add(
  faApprove,
  faAdd,
  faArrowDropDown,
  faBars,
  faBellRing,
  faBusiness,
  faCalendarAlt,
  faCalendarDay,
  faCheck,
  faChevronDown,
  faCircle,
  faClock,
  faCreditCard,
  faDownload,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFavoriteAddress,
  faFilter,
  faHelmet,
  faHelp,
  faHexagonBullet,
  faHistory,
  faIncidence,
  faInfoCircle,
  faInvoices,
  faLogOut,
  faMapMarkerAlt,
  faMoon,
  faOptions,
  faPencilAlt,
  faPhoneCall,
  faPrint,
  faQuestionCircle,
  faRestaurant,
  faSearch,
  faSlidersH,
  faSort,
  faStar,
  faStatistics,
  faStoreAlt,
  faSun,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUser,
  faWhatsApp,
  faSignFile,
  faZubut,
  faZubutBike,
  faZubutBikeActive,
  faZubutCar,
  faZubutMotorcycle,
  faReload,
  faCopyDestination
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
