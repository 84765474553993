import VueRouter from "vue-router";
import Vue from "vue";
import Routes from "@/constants/routes/paths";

/* Layouts */
const InitLayout = () => import("@/app/layouts/InitLayout.vue");
const MainLayout = () => import("@/app/layouts/MainLayout.vue");
const TrackingLayout = () => import("@/app/layouts/TrackingLayout.vue");

/* Views */
const Dashboard = () => import("@/app/views/Dashboard/Dashboard.vue");
const Forgot = () => import("@/app/views/Login/Forgot.vue");
const HourRent = () => import("@/app/views/HourRent/HourRent.vue");
const HourRentReservations = () =>
  import("@/app/views/HourRent/HourRentReservations.vue");
const HourRentDetail = () =>
  import("@/app/views/HourRentDetail/HourRentDetail.vue");
const Login = () => import("@/app/views/Login/Login.vue");
const Profile = () => import("@/app/views/Profile/Profile.vue");
const CreateBranch = () =>
  import("@/app/views/Profile/CreateBranch/ClientCreateBranchMobile");
const ExpressCreation = () =>
  import("@/app/views/ExpressCreation/ExpressCreation");
const MultiplePackagesCreation = () =>
  import("@/app/views/MultiplePackagesCreation/MultiplePackagesCreation");
const PackageCreation = () =>
  import("@/app/views/PackageCreation/PackageCreation");
const Reconnect = () => import("@/app/views/Login/Reconnect.vue");
const ServiceDetail = () =>
  import("@/app/views/ServiceDetail/ServiceDetail.vue");
const Terms = () => import("@/app/views/Terms/Terms.vue");
const Tracking = () => import("@/app/views/Tracking/Tracking.vue");
const UpdatePassword = () => import("@/app/views/Login/UpdatePassword.vue");
const Confirmation = () => import("@/app/views/Login/Confirmation.vue");
const ReservationsDetail = () =>
  import("@/app/views/ReservationsDetail/ReservationsDetail.vue");
const DeliveryDetail = () =>
  import("@/app/views/DeliveryDetail/DeliveryDetail.vue");
const NotFound = () => import("@/app/views/NotFound.vue");

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "*",
      redirect: { name: "login" }
    },
    {
      path: "/",
      name: "home",
      component: MainLayout,
      redirect: {
        name: "dashboard"
      },
      children: [
        {
          path: `/${Routes.DASHBOARD}`,
          name: "dashboard",
          components: {
            default: Dashboard
          },
          meta: { requiresAuth: true },
          children: [
            {
              path: `/${Routes.CREATE_DELIVERY}/express`,
              name: "createExpressDelivery",
              meta: { requiresAuth: true },
              components: {
                default: ExpressCreation
              }
            },
            {
              path: `/${Routes.CREATE_DELIVERY}/guias`,
              name: "createPackageDelivery",
              meta: { requiresAuth: true },
              components: {
                default: PackageCreation
              }
            },
            {
              path: `/${Routes.CREATE_DELIVERY}/guias-multiples`,
              name: "createMultiplePackagesDeliverys",
              meta: { requiresAuth: true },
              components: {
                default: MultiplePackagesCreation
              }
            }
          ]
        },
        {
          path: `/${Routes.PROFILE}`,
          name: "profile",
          components: {
            default: Profile
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.ADDRESSES}`,
          name: "addresses",
          components: {
            default: Profile
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.HISTORY}`,
          name: "deliveries",
          components: {
            default: Profile
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.BRANCHES}`,
          name: "branches",
          components: {
            default: Profile
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.PAYMENT_METHODS}`,
          name: "payment-methods",
          components: {
            default: Profile
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.BILLING}`,
          name: "billing",
          components: {
            default: Profile
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.SERVICES}/:id`,
          name: "serviceDetail",
          components: {
            default: ServiceDetail
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.HELP}`,
          name: "help",
          components: {
            default: Terms
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.CREATE_BRANCH}`,
          name: "createBranch",
          components: {
            default: CreateBranch
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.RENTS}`,
          name: "rents",
          components: {
            default: HourRent
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.RESERVATIONS}`,
          name: "reservations",
          components: {
            default: HourRentReservations
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.RENTS}/:id`,
          name: "rentDetail",
          components: {
            default: HourRentDetail
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.RESERVATIONS}/:id`,
          name: "reservationDetail",
          components: {
            default: ReservationsDetail
          },
          meta: { requiresAuth: true }
        },
        {
          path: `/${Routes.DELIVERIES}/:id`,
          name: "deliveryDetail",
          components: {
            default: DeliveryDetail
          },
          meta: { requiresAuth: true }
        }
      ]
    },
    {
      path: "/inicio",
      name: "init",
      component: InitLayout,
      redirect: {
        name: "login"
      },
      children: [
        {
          path: `/${Routes.LOGIN}`,
          name: "login",
          props: route => ({ query: route.query.t }),
          components: {
            default: Login
          }
        },
        {
          path: `/${Routes.CONFIRMATION}/:id`,
          name: "confirmation",
          components: {
            default: Confirmation
          }
        },
        {
          path: `/${Routes.RECONNECT}`,
          name: "reconnect",
          components: {
            default: Reconnect
          }
        },
        {
          path: `/${Routes.RETRIEVE_PASSWORD}`,
          name: "forgot",
          components: {
            default: Forgot
          }
        },
        {
          path: `/${Routes.RETRIEVE_PASSWORD}/:secret`,
          name: "updatePassword",
          components: {
            default: UpdatePassword
          }
        }
      ]
    },
    {
      path: "/tracking",
      name: "tracking-home",
      component: TrackingLayout,
      redirect: {
        name: "tracking"
      },
      children: [
        {
          path: `/${Routes.TRACKING}/:type/:trackingId`,
          name: "tracking-type",
          components: {
            default: Tracking
          }
        }
      ]
    },
    { path: "/404", name: "404", component: NotFound }
  ]
});

export default router;
