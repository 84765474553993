import { captureError } from "@/plugins/error-tracking";
import Deliveries from "@/services/deliveries";
import Services from "@/services/services";
import Parcels from "@/services/parcels";
import ServiceType from "@zubut/common/src/constants/services/type";
import * as mutation from "./mutations-types";

export function getDeliveries({ commit }, { filter, where }) {
  return new Promise((resolve, reject) => {
    Deliveries.listing({
      filter,
      where
    })
      .then(({ data, meta }) => {
        commit(mutation.SET_DELIVERIES, data);
        if (meta && meta.skip === 0) {
          commit(mutation.UPDATE_DELIVERIES_TOTAL, meta.count);
        }
        resolve({ data, meta });
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function cancel(_, data) {
  return new Promise((resolve, reject) => {
    if (ServiceType.isParcel(data.type)) {
      Parcels.cancel(data.id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          captureError(err);
          reject(err);
        });
    } else {
      Services.cancel({
        id: data.zubutId
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          captureError(err);
          reject(err);
        });
    }
  });
}
