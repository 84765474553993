<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size || width"
    :height="size || height"
    :viewBox="viewBox"
    :aria-labelledby="name"
    role="presentation"
    :class="[{ 'flip-vertical': flip === 'vertical' }]"
    :style="{ transform: rotate ? `rotate(${rotate}deg)` : '' }"
  >
    <g :fill="color">
      <component
        v-bind:is="iconComponent"
        :class="{
          'icon-active': variant === 'active',
          'icon-white': variant === 'white',
          'icon-hover': hover
        }"
        :variant="variant"
        :hover="hover"
      ></component>
    </g>
  </svg>
</template>

<script>
export default {
  name: "ZIcon",

  props: {
    name: {
      type: String,
      default: "AddAlt",
      required: true
    },
    size: {
      type: [Number, String],
      default: 0
    },
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    },
    color: {
      type: String,
      default: "#656B80"
    },
    flip: {
      type: String,
      default: "",
      validator: val => !val || ["vertical"].indexOf(val) > -1
    },
    variant: {
      type: String,
      default: "normal",
      validator: val => ["normal", "active", "white"].indexOf(val) > -1
    },
    hover: {
      type: Boolean,
      default: false
    },
    rotate: {
      type: [String, Number],
      default: 0
    },
    viewBox: {
      type: String,
      default: "0 0 24 24"
    }
  },

  data() {
    return {
      iconComponent: ""
    };
  },

  watch: {
    name: {
      immediate: true,
      handler() {
        this.iconComponent = () => import(`./icons/${this.name}Icon.vue`);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/variables/colors.scss";

svg {
  display: inline-block;
  vertical-align: baseline;

  &.flip-vertical {
    transform: rotate(180deg);
  }
}

.icon-active,
.icon-hover:hover {
  fill: var(--primary);
}

.icon-white {
  fill: $white;
}
</style>
