import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("Parcels");

const getCurrentId = () => {
  const user = JSON.parse(localStorage.getItem("loopback-token"));
  if (user) {
    return user.userId;
  }
  return null;
};

const parcels = Object.assign(
  {},
  {
    bulkRequest(data) {
      return loopback.post("/Parcels/bulkRequest", data);
    },
    cancel(parcelId) {
      return loopback.post("/Parcels/cancel", { parcels: [parcelId] });
    },
    downloadParcelBulkZpls(objectKey) {
      return loopback.get("/Parcels/downloadParcelBulkZpls", {
        params: { objectKey },
        responseType: "blob"
      });
    },

    downloadProofOfDelivery(parcelId) {
      return loopback.get(`/Parcels/${parcelId}/proofOfDelivery`, {
        responseType: "blob"
      });
    },

    logs(id, { filter = {}, where = {} }) {
      const params = {};
      if (Object.keys(filter).length) {
        params.filter = filter;
      }
      if (Object.keys(where).length) {
        params.where = where;
      }
      return loopback.get(`/Parcels/${id}/logListing`, { params });
    },
    parseFromCsv(formData) {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };
      return loopback.post("/Parcels/parseFromCsv", formData, config);
    },
    shippingLabel(parcelId, format = "png") {
      return loopback.get("/Parcels/shippingLabel", {
        params: { parcelId, format }
      });
    },

    trackingInfo(parcelId) {
      return loopback.get(`/Parcels/trackingInfo?parcelId=${parcelId}`);
    },
    earliestDeliveryDate(params) {
      return loopback.get("/Parcels/earliestDeliveryDate", { params });
    },
    estimation(params) {
      return loopback.get("/Parcels/estimation", { params });
    },
    downloadProofOfDelivery(parcelId) {
      return loopback.get(`/Parcels/${parcelId}/proofOfDelivery`, {
        responseType: "blob"
      });
    },
    request(data) {
      return loopback.post("/Parcels/request", data);
    }
  },
  modelObject
);

export default parcels;
