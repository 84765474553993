import { captureError } from "@/plugins/error-tracking";
import Clients from "@/services/clients";
import * as mutation from "./mutations-types";

export function getBranches({ state, commit }) {
  if (!state.branches.length) {
    return new Promise((resolve, reject) => {
      Clients.branches()
        .then(clients => {
          commit(mutation.SET_BRANCHES, clients);
          resolve(clients);
        })
        .catch(err => {
          captureError(err);
          reject(err);
        });
    });
  }
}
