// Reservations mutations
export const SET_RESERVATIONS = "SET_RESERVATIONS";
export const UPDATE_RESERVATIONS_TOTAL = "UPDATE_RESERVATIONS_TOTAL";

export const SET_FILTER_CLIENT = "setFilterClient";
export const SET_FILTER_DATE = "setFilterDate";
export const SET_FILTER_VEHICLE_TYPE = "setFilterVehicleType";
export const SET_FILTER_STATUS = "setFilterStatus";
export const SET_FILTER_SEARCH = "setFilterSearch";
export const TOGGLE_UPDATE_INDICATOR = "toggleUpdateIndicator";
