<template>
  <transition-expand>
    <div
      v-show="show"
      class="message flex-column flex-sm-row"
      :class="[type, { dismissible: dismissible }]"
      @click.prevent="dismiss"
    >
      <slot name="content">
        <div
          class="d-flex flex-column py-1 align-items-center flex-grow-1 flex-md-row py-sm-0"
          :class="{ 'has-icon': hasIcon }"
        >
          <div class="d-flex align-items-center my-1 my-md-0">
            <z-icon
              v-if="hasIcon"
              :name="iconSet"
              :color="iconFill"
              size="24"
              class="mr-3"
            />
            <div v-show="title" class="font-weight-bold" data-test-id="title">
              {{ title }}
            </div>
          </div>
          <p v-show="message" data-test-id="message">
            {{ message }}
          </p>
        </div>
      </slot>
    </div>
  </transition-expand>
</template>

<script>
import TransitionExpand from "./Anim/TransitionExpand";
import ZIcon from "./ZIcon";

export default {
  name: "QuickMessage",
  components: {
    TransitionExpand,
    ZIcon
  },
  props: {
    message: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "info",
      validator: value =>
        ["info", "error", "success", "secondary"].indexOf(value) !== -1
    },
    show: {
      type: Boolean,
      default: false
    },
    duration: {
      type: Number,
      default: 5000
    },
    timeout: {
      type: Boolean,
      default: true
    },
    withIcon: {
      type: Boolean,
      defaul: true
    },
    dismissible: {
      type: Boolean,
      default: false
    },
    iconName: {
      type: String,
      default: null
    },
    iconColor: {
      type: String,
      defaul: null
    }
  },
  computed: {
    hasIcon() {
      return this.withIcon || (this.iconName != null && this.iconName != "");
    },
    iconSet() {
      if (this.iconName) {
        return this.iconName;
      }
      if (this.type === "error") {
        return "Alert";
      }
      if (this.type === "success") {
        return "Check";
      }
      if (this.type === "info") {
        return "Info";
      }
      return null;
    },
    iconFill() {
      if (this.iconColor) {
        return this.iconColor;
      }
      if (this.type === "error") {
        return "#963b3c";
      }
      if (this.type === "success") {
        return "#28A745";
      }
      if (this.type === "info") {
        return "var(--primary)";
      }
      return null;
    }
  },
  watch: {
    show(newVal) {
      if (newVal && this.timeout) {
        setTimeout(() => {
          this.$emit("update:show", false);
        }, this.duration);
      }
    }
  },
  methods: {
    dismiss() {
      if (this.dismissible) {
        this.$emit("update:show", false);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/variables/colors.scss";

.expand-enter-active,
.expand-leave-active {
  transition-property: opacity, height;
}

.expand-enter,
.expand-leave-to {
  opacity: 0;
}

.message {
  display: flex;
  padding: 0.6rem 1rem;
  font-size: 12px;
  align-items: center;
  border-radius: 6px;

  &.dismissible {
    cursor: pointer;
  }

  p {
    padding: 0;
    margin: 0;
    letter-spacing: 0.5px;
  }

  .has-icon p {
    margin-left: 0.75rem;
  }

  .dismissible p {
    cursor: pointer;
  }

  a {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &.error {
    background-color: $tutu;
    color: $mexican-red;
  }

  &.success {
    background-color: $rice-flower;
    color: $forest-green;
  }

  &.info {
    background-color: var(--primary-bg);
    color: var(--primary);
  }

  &.secondary {
    background-color: $solitude;
    color: $nero;
  }
}
</style>
