import { captureError } from "@/plugins/error-tracking";
import * as mutation from "./mutations-types";
import Logs from "@/services/logs";
import Type from "@zubut/common/src/constants/services/type";
import BrowserNotification from "@zubut/common/src/models/browser-notification";

const notification = new BrowserNotification();

export function updateNotifications({ commit }, data) {
  commit(mutation.UPDATE_NOTIFICATIONS, data);
}

export function newUserNotification({ commit }, data) {
  notification.config.title = data.title;
  notification.options.body = data.message;
  notification.requestNotificationPermissions();
  commit(mutation.ADD_NOTIFICATION, data);
  commit(mutation.UPDATE_HAS_NEW_NOTIFICATIONS, true);
  const isDelivery =
    Type.isMultiPoint(data.serviceType) ||
    Type.isParcel(data.serviceType) ||
    Type.isPackageService(data.serviceType);
  if (data.serviceId) {
    // Validate the driver id because the ws will send many messages of assigning services
    if (isDelivery && data.driverId) {
      commit("dashboard/deliveries/toggleUpdateIndicator", true);
    } else if (Type.isReservation(data.serviceType)) {
      // Reservations
      commit("dashboard/reservations/toggleUpdateIndicator", true);
    }
  }
}

export function getNotifications({ commit }, filter) {
  return new Promise((resolve, reject) => {
    Logs.getNotifications(filter)
      .then(response => {
        commit(mutation.SET_NOTIFICATIONS, response.data);
        resolve(response.data);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function deleteNotifications({ commit }) {
  commit(mutation.DELETE_NOTIFICATIONS);
}
