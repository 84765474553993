import { captureError } from "@/plugins/error-tracking";
import Deliveries from "@/services/deliveries";

/**
 * @typedef Delivery
 * @type {Object}
 * @property {string} id
 * @property {date} createdAt
 * @property {number} status
 * @property {string} deliverTo
 * @property {number} deliveryType
 * @property {number} serviceStatus
 * @property {number?} parcelStatus
 * @property {string} driver
 * @property {number} vehicleType
 * @property {string} clientName
 * @property {string} clientId
 * @property {string?} companyid
 * @property {string} zubutId
 * @property {number?} subtotal
 * @property {number?} tax
 * @property {number?} total
 */

/**
 *
 * @param {*} _
 * @param {object} filter Loopback filter
 * @return {Promise<Array.<Delivery>} A promise to return array of deliveries.
 */
export function getDeliveries(_, filter) {
  return new Promise((resolve, reject) => {
    Deliveries.history(filter)
      .then(({ data: deliveries, meta }) => {
        resolve({ data: deliveries, meta });
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}
