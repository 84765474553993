import modelObject from "@/services/lb.services";

modelObject.setModel("Cities");

const Cities = Object.assign(
  {},
  {
    relation: ""
  },
  modelObject
);

export default Cities;
