import { captureError } from "@/plugins/error-tracking";
import loopback from "@/services/loopback";
import Clients from "@/services/clients";
import ws from "@/websockets";
import auth from "@/app/authentication";
import * as mutation from "./mutations-types";

/**
 * Sync loopback token with current state
 */
export function syncToken({ commit }) {
  if (loopback.token && auth.getLoggedUserData() !== null) {
    commit(mutation.UPDATE_ACCESS_TOKEN, loopback.token);
    ws.init();
  }
}

export function syncRouter(
  { commit, rootGetters, rootState, dispatch },
  router
) {
  dispatch("syncToken");

  router.beforeEach((to, from, next) => {
    // Check if user has access to the route
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (Clients.getCurrentId() === null) {
        next({ path: "/inicio-sesion" });
      } else {
        /* Load client data on refresh */
        if (!rootState.user.id) {
          dispatch("user/loadAccount", Clients.getCurrentId(), { root: true });
        }

        // Check if client is able to create this type of service
        const isExpressServiceEnabled =
          rootGetters["user/isExpressServiceEnabled"];
        const isParcelServiceEnabled =
          rootGetters["user/isParcelServiceEnabled"];
        if (
          (to.name === "createExpressDelivery" && !isExpressServiceEnabled) ||
          (to.name === "createPackageDelivery" && !isParcelServiceEnabled)
        ) {
          next({ name: "dashboard" });
        } else {
          next();
        }
      }
    } else {
      if (to.name === "login" && Clients.getCurrentId()) {
        next({ name: "dashboard" });
      }
      next();
    }
  });

  // Hide 404 page
  router.beforeResolve((_, __, next) => {
    commit("setDisplayNotFound", false, { root: true });
    next();
  });
}

export function validateSession({ state }) {
  console.log("validateSession");
  return new Promise(resolve => {
    if (state.access_token === null) {
      return resolve(false);
    }
    resolve(true);
    ws.init();
  });
}

export function login({ commit, dispatch, state }, { email, password }) {
  console.log("login");
  // Clean old token
  commit(mutation.CLEAN_ACCESS_TOKEN);
  loopback.removeToken();

  return new Promise((resolve, reject) => {
    Clients.login({ email, password })
      .then(res => {
        // Commit new token
        commit(mutation.UPDATE_ACCESS_TOKEN, res);

        // Update Loopback Token
        if (state.access_token === null) {
          loopback.removeToken();
          loopback.setToken(res);
        } else {
          loopback.setToken(res);
        }

        ws.init();

        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function logout({ commit, state }, deleteUser = false) {
  return new Promise((resolve, reject) => {
    Clients.logout({ accessToken: state.access_token })
      .then(() => {
        // Remove user
        if (deleteUser) {
          // eslint-disable-next-line
                    // FIXME this is broke in sometime
          // commit(mutation.CLEAN_USER);
        }

        // Disconnect sockets
        ws.cleanSocket();

        // Remove token
        commit(mutation.CLEAN_ACCESS_TOKEN);
        loopback.removeToken();

        resolve();
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function logoutSync({ commit }) {
  console.log("logoutSync");
  // Disconnect sockets
  ws.cleanSocket();

  // Remove token
  commit(mutation.CLEAN_ACCESS_TOKEN);
  loopback.removeToken();
  console.log("logoutSync ok");
}
