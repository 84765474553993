<template>
  <b-tab class="z-tab" v-bind="$attrs" v-on="$listeners">
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </b-tab>
</template>

<script>
export default {
  name: "ZTab"
};
</script>

<style lang="scss">
@import "../styles/variables/colors.scss";

.z-tabs .nav-item .nav-link {
  font-weight: 500;
  color: $comet;
  position: relative;

  &.active {
    color: $white;
  }
}

.z-tabs .nav-pills .nav-link.active {
  background: var(--primary);
  border-radius: 6px;
  font-weight: 600;
}

.z-tabs .nav-fill .nav-item {
  width: 100%;
}

@include media-breakpoint-down(sm) {
  .nav-link {
    padding: 0.8rem 1rem !important;
  }
}
@include media-breakpoint-up(md) {
  .z-tabs .nav-item .nav-link.active::before {
    content: "";
    position: absolute;
    bottom: -1em;
    left: 0px;
    width: 100%;
    height: 2px;
    background-color: var(--primary);
    z-index: 2;
  }
}
</style>
