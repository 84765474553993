export default {
  cityOptions(state) {
    return [{ text: "Todas", value: null }].concat(
      state.cities.map(el => ({
        value: el.id,
        text: el.name
      }))
    );
  }
};
