import currency from "currency.js";
import { roundTo } from "./numbers";

/* Not using Intl.NumberFormat until full Safari support */
const moneyFormat = (value: number) =>
  currency(value, { symbol: "$", separator: ",", decimal: "." });

export function formatMoney(amount: number): string {
  return moneyFormat(amount).format();
}

export function taxSubtotal(subtotal: null | number) {
  if (subtotal == null) return null;
  return roundTo(subtotal * 1.16, 2);
}
