import Parcels from "@/services/parcels";
import { formatSendPhone } from "@zubut/common/src/utils/strings";

export default {
  bulkRequest(_, parcels) {
    return Parcels.bulkRequest({ parcels });
  },
  downloadParcelBulkZpls(_, objectKey) {
    return Parcels.downloadParcelBulkZpls(objectKey);
  },
  parseFromCsv({ state, getters }, file) {
    const destinationOrigin = getters.getDestinationOrigin;
    const origin = {
      address: destinationOrigin.address.address,
      position: destinationOrigin.address.position,
      name: destinationOrigin.name,
      phone: formatSendPhone(destinationOrigin.phone),
      information: destinationOrigin.information
    };
    const deliveryMode = state.deliveryMode;

    const schedule = destinationOrigin.schedule;

    const additional = state.additionalOptions;

    const formData = new FormData();

    formData.append("parcelsFile", file);
    formData.append("origin", JSON.stringify(origin));
    formData.append("deliveryMode", deliveryMode);
    formData.append("pickUpSchedule", schedule);
    formData.append("deliverySchedule", schedule);
    formData.append("additional", JSON.stringify(additional));
    return Parcels.parseFromCsv(formData);
  }
};
