import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("Reservations");

const Reservations = Object.assign(
  {},
  {
    relation: "",

    calculateHourlyRate(data) {
      return loopback.post("/Reservations/calculateHourlyRate", data);
    },

    estimation(data) {
      return loopback.get("/Reservations/estimation", { params: data });
    },

    generate(data) {
      return loopback.post("/Reservations/generate", data);
    },

    getDetails(id) {
      return loopback.get(`/Reservations/${id}/details`);
    },

    listing(data) {
      return loopback.get("/Reservations/listing", { params: data });
    },

    lastReservation(reservationWeek) {
      return loopback.get(
        `/Reservations/lastReservation?reservationWeek=${reservationWeek}`
      );
    },

    services({ filter, where }) {
      return loopback.get("/Reservations/services", {
        params: { filter, where }
      });
    }
  },
  modelObject
);

export default Reservations;
