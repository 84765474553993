export default {
  // Reservations getters
  getReservations(state) {
    return state.reservations;
  },
  getReservationsTotal(state) {
    return state.reservationsTotal;
  },
  getReservationsFilters(state) {
    return state.reservationsFilter;
  },
  getReservationsFilterText(state) {
    return state.reservationsFilter.text;
  },
  getReservationsFilterClient(state) {
    return state.reservationsFilter.clientId;
  },
  // Sockets
  getHasUpdates(state) {
    return state.hasUpdates;
  }
};
