const RESTAURANT = "Farmacia/Restaurante";
const ECOMMERCE = "E-commerce";
const STORE = "Tienda/Retail";
const BUSINESS_LOCAL = "Negocio";
const OTHER = "Otro";

const NUM_RESTAURANT = 0;
const NUM_ECOMMERCE = 1;
const NUM_BUSINESS_LOCAL = 2;
const NUM_STORE = 3;
const NUM_OTHER = 4;

const get = {};
get[NUM_RESTAURANT] = RESTAURANT;
get[NUM_ECOMMERCE] = ECOMMERCE;
get[NUM_BUSINESS_LOCAL] = BUSINESS_LOCAL;
get[NUM_STORE] = STORE;
get[NUM_OTHER] = OTHER;

const options = [
  { value: NUM_RESTAURANT, text: RESTAURANT },
  { value: NUM_ECOMMERCE, text: ECOMMERCE },
  { value: NUM_BUSINESS_LOCAL, text: BUSINESS_LOCAL },
  { value: NUM_STORE, text: STORE },
  { value: NUM_OTHER, text: OTHER }
];

export default {
  get,
  options,
  NUM_RESTAURANT,
  NUM_ECOMMERCE,
  NUM_STORE,
  NUM_BUSINESS_LOCAL,
  NUM_OTHER
};
