import DeliveryMode from "@zubut/common/src/constants/parcels/deliveryMode";

const defaultDestination = {
  address: "",
  name: "",
  email: "",
  phone: "",
  information: "",
  schedule: 0
};

const defaultState = {
  destinations: [
    {
      index: 0,
      data: defaultDestination
    },
    {
      index: 1,
      data: defaultDestination
    }
  ],
  additionalOptions: {
    externalId: "",
    signature: true
  },
  measures: {
    valid: false,
    width: null,
    height: null,
    length: null,
    weight: null,
    volumetricWeight: null
  },
  deliveryMode: DeliveryMode.NUM_SAME_DAY
};

export default defaultState;
