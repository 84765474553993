import * as actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import state from "./state";
import multiple from "./multiple";

export default {
  namespaced: true,
  state: Object.assign({}, state),
  actions,
  mutations,
  getters,
  modules: {
    multiple
  }
};
