import Vue from "vue";
import VueI18n from "vue-i18n";
import commonEsCO from "@zubut/common/src/locales/es-CO";
import commonEsMX from "@zubut/common/src/locales/es-MX";
import esCO from "@/locales/es-CO";
import esMX from "@/locales/es-MX";
import merge from "lodash/merge";

Vue.use(VueI18n);

// Install i18n plugin.
// The locales used in this project are merged common and also have a higher priority
const i18n = new VueI18n({
  locale: "es-MX",
  fallbackLocale: "es-MX",
  messages: {
    "es-CO": merge(commonEsCO, esCO),
    "es-MX": merge(commonEsMX, esMX)
  }
});

export default i18n;
