import { captureError } from "@/plugins/error-tracking";
import Services from "@/services/services";
import * as mutation from "./mutations-types";
import { formatSendPhone } from "@zubut/common/src/utils/strings";

export function requestService({ dispatch, state, rootGetters }) {
  return new Promise((resolve, reject) => {
    const destinations = state.destinations.map(dest => {
      return {
        address: dest.data.address.address,
        position: dest.data.address.position,
        information: dest.data.information,
        name: dest.data.name,
        phone: formatSendPhone(dest.data.phone)
      };
    });
    const additional = state.additionalOptions;
    const vehicleType = state.vehicleType;
    const paymentMethod = state.paymentMethod;
    const km = state.rates.km;
    const estimatedTime = state.rates.minutes;
    const estimatedRate = state.rates.cost;
    let walletId = "";
    let cardId = "";
    if (paymentMethod === "wallet") {
      walletId = rootGetters["user/getWallet"].id;
    } else {
      cardId = state.cardId;
    }

    Services.request({
      destinations,
      additional,
      vehicleType,
      walletId,
      cardId,
      km,
      estimatedTime,
      estimatedRate
    })
      .then((res, err) => {
        if (err) {
          reject(err);
          return;
        }
        dispatch("reset");
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function serviceRate({ commit, state, rootGetters }) {
  return new Promise((resolve, reject) => {
    const destinations = state.destinations.map(dest => {
      return {
        address: dest.data.address.address,
        position: dest.data.address.position,
        information: dest.data.information,
        name: dest.data.name,
        phone: dest.data.phone
      };
    });
    const additional = state.additionalOptions;
    additional.km = state.rates.km;
    const vehicleType = state.vehicleType;
    const clientId = rootGetters["user/getUserId"];

    Services.serviceRate({
      destinations,
      additional,
      vehicleType,
      clientId
    })
      .then(
        res => {
          const vehicleRates = {
            bikeKMCost: res.taxedParameters.bikeKMTotal,
            carKMCost: res.taxedParameters.carKMTotal,
            ecoKMCost: res.taxedParameters.ecoKMTotal
          };
          commit(mutation.SET_RATES_COST, res.taxedRate);
          commit(mutation.SET_VEHICLE_RATES, vehicleRates);
          commit(mutation.SET_RATES_CURRENCY, res.currency);
          resolve(res);
        },
        reason => {
          captureError(reason);
          reject(reason);
        }
      )
      .catch(reason => {
        captureError(reason);
        reject(reason);
      });
  });
}

export function setTimeAndDistance({ commit }, { km, minutes }) {
  commit(mutation.SET_RATES_MINUTES, minutes);
  commit(mutation.SET_RATES_KM, km);
}

export function reset({ commit }) {
  commit(mutation.RESET);
}
