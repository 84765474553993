export function getSavedEvents(state) {
  return state.events.filter(ev => ev.saved);
}

export function getNonReservedEvents(state) {
  return state.events.filter(ev => ev.saved && !ev.reserved);
}

export function getNotSavedEvents(state) {
  return state.events.filter(ev => !ev.saved);
}

export function getEvent(state) {
  return eventId => {
    if (eventId < 0) {
      return null;
    }
    return state.events.find(ev => ev.id === eventId);
  };
}

export function getNoAddressEvents(state) {
  return state.events.filter(ev => !ev.address);
}

export function getAmountToPay(state) {
  return parseFloat(
    (
      state.events.reduce((acum, act) => {
        if (!act.saved || act.reserved) {
          return 0;
        }
        return acum + act.cost;
      }, 0) * 1.16
    ).toFixed(2)
  );
}
