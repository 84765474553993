import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("UserAddresses");

const UserAddresses = Object.assign(
  {},
  {
    relation: "",

    setAddressDefault(data) {
      return loopback.post("/UserAddresses/setDefault", data);
    }
  },
  modelObject
);

export default UserAddresses;
