import cloneDeep from "lodash/cloneDeep";
import defaultState from "./state";
import * as mutation from "./mutation-types";

export default {
  [mutation.RESET](state) {
    const s = defaultState;
    Object.keys(s).forEach(key => {
      state[key] = cloneDeep(s[key]);
    });
  },

  [mutation.ADD_EVENT](state, event) {
    state.events = [...state.events, event];
  },

  [mutation.ADD_EVENTS](state, events) {
    state.events = [...state.events, ...events];
  },

  [mutation.UPDATE_EVENT](state, event) {
    state.events = state.events.map(ev =>
      ev.id && ev.id === event.id ? { ...event } : { ...ev }
    );
  },

  [mutation.DELETE_EVENT](state, event) {
    state.events = state.events.filter(ev => ev.id !== event.id);
  },

  [mutation.INCREMENT_EVENT_ID](state) {
    state.eventId += 1;
  },

  [mutation.RESERVE_ALL_EVENTS](state) {
    state.events = state.events.map(ev => ({
      ...ev,
      reserved: true,
      editable: false
    }));
  },

  [mutation.REMOVE_NOT_SAVED_EVENTS](state) {
    state.events = state.events.filter(ev => ev.saved);
  },

  [mutation.SET_DEFAULT_EVENT_ADDRESS](state, address) {
    state.defaultAddress = cloneDeep(address);
  },

  [mutation.REMOVE_DEFAULT_EVENT_ADDRESS](state) {
    state.defaultAddress = null;
  },

  [mutation.UPDATE_NOT_RESERVED_EVENTS_ADDRESS](state) {
    state.events = state.events.map(ev => {
      if (!ev.reserved) {
        return { ...ev, address: cloneDeep(state.defaultAddress) };
      }
      return ev;
    });
  },

  [mutation.SET_EVENTS](state, events) {
    state.events = [...state.events, ...events];
  },

  [mutation.ADD_DATE_FILTER](state, filter) {
    state.dateFilters = [...state.dateFilters, filter];
  },

  [mutation.UPDATE_MAKING_RESERVATION](state, makingReservation) {
    state.makingReservation = makingReservation;
  }
};
