const defaultState = {
  filter: {
    status: { text: "Todos", value: null },
    date: { text: "Esta semana", value: 2 },
    serviceType: { text: "Todos", value: null },
    vehicleType: { text: "Todos", value: null }
  },
  previousFilter: null,
  date: {
    from: "",
    until: ""
  },
  current: null,
  driver: null,
  selectedActiveService: null
};

export default defaultState;
