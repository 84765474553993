import DateConstants from "../constants/filters/date";
import { differenceInSeconds, format as dateFnsFormat } from "date-fns";
import differenceInHours from "date-fns/differenceInHours";
import differenceInCalendarYears from "date-fns/differenceInCalendarDays";
import es from "date-fns/locale/es";
import sub from "date-fns/sub";
import parseISO from "date-fns/parseISO";
import startOfDay from "date-fns/startOfDay";
import startOfTomorrow from "date-fns/startOfTomorrow";
import startOfYesterday from "date-fns/startOfYesterday";
import startOfISOWeek from "date-fns/startOfISOWeek";
import endOfISOWeek from "date-fns/endOfISOWeek";
import startOfMonth from "date-fns/startOfMonth";
import startOfYear from "date-fns/startOfYear";
import endOfDay from "date-fns/endOfDay";
import endOfTomorrow from "date-fns/endOfTomorrow";
import endOfYesterday from "date-fns/endOfYesterday";
import endOfMonth from "date-fns/endOfMonth";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";

export function dateRange(value: number) {
  const date: any = {};
  switch (value) {
    case DateConstants.NUM_TODAY:
      date.from = startOfDay(Date.now());
      date.until = endOfDay(Date.now());
      break;
    case DateConstants.NUM_TOMORROW:
      date.from = startOfTomorrow();
      date.until = endOfTomorrow();
      break;
    case DateConstants.NUM_YESTERDAY:
      date.from = startOfYesterday();
      date.until = endOfYesterday();
      break;
    case DateConstants.NUM_THIS_WEEK:
      date.from = startOfISOWeek(Date.now());
      date.until = endOfISOWeek(Date.now());
      break;
    case DateConstants.NUM_LAST_WEEK:
      date.from = sub(startOfISOWeek(Date.now()), { weeks: 1 });
      date.until = sub(endOfISOWeek(Date.now()), { weeks: 1 });
      break;
    case DateConstants.NUM_THIS_MONTH:
      date.from = startOfMonth(Date.now());
      date.until = endOfMonth(Date.now());
      break;
    case DateConstants.NUM_LAST_MONTH:
      date.from = sub(startOfMonth(Date.now()), { months: 1 });
      date.until = sub(endOfMonth(Date.now()), { months: 1 });
      break;
    case DateConstants.NUM_THIS_YEAR:
      date.from = startOfYear(Date.now());
      date.until = Date.now();
      break;
    default:
      date.from = null;
      date.until = null;
      break;
  }
  return date;
}

/*
 * Transforms dates as follows: 05/feb./2019 13:01 -> 05/Feb/2019 13:01
 */
export function formatCreatedAtDate(createdAt: string) {
  let newDate = createdAt;
  newDate = newDate.replace(/\./g, "");
  newDate =
    newDate.substr(0, 3) +
    newDate.substr(3, 1).toUpperCase() +
    newDate.substr(4);
  return newDate;
}

export function minutesToHours(n: number) {
  const hours = (n / 60) ^ 0; // eslint-disable-line no-bitwise
  const minutes = n % 60;
  return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
}

export function format(date: Date, formatStr = "PP") {
  return dateFnsFormat(date, formatStr, {
    locale: es
  });
}

/**
 * Receives an ISO 8601 date (p. e. "2021-10-11T00:00:00-06:00"), and formats it.
 * @returns {Srtring} Formatted date.
 * @param {String} date - ISO date
 * @param {String} formatStr - Format to return date [https://date-fns.org/v2.16.1/docs/format]
 */
export function formatISO(date: string, formatStr = "PP") {
  return dateFnsFormat(parseISO(date), formatStr, {
    locale: es
  });
}

/**
 * Receives a date object and formats is as ISO 8601 (p. e. "2021-10-11T00:00:00-06:00").
 * @param {Date} date - Date object
 * @returns {Srtring} Formatted date.
 */
export function formatToISO(date: Date) {
  return format(date, "yyyy-MM-dd'T'HH:mm:ssxxx");
}

/**
 * Return the difference in hours of two dates
 *
 * @param {String} end - End date
 * @param {String} start - Start date
 * @returns {Number} Difference between both dates in hours
 */
export function diffInHours(start: string, end: string) {
  return differenceInHours(new Date(end), new Date(start));
}

/**
 * Return the difference in calendar days of two dates
 *
 * @param {String} end - End date
 * @param {String} start - Start date
 * @returns {Number} Difference between both dates in calendar days
 */
export function diffInDays(start: string, end: string) {
  return differenceInCalendarYears(new Date(end), new Date(start));
}
/**
 * Return the difference in seconds of two dates
 *
 * @param {String} end - End date
 * @param {String} start - Start date
 * @returns {Number} Difference between both dates in seconds
 */
export function diffInSeconds(start: string, end: string) {
  return differenceInSeconds(new Date(end), new Date(start));
}

/* Tranforms decimal to Hours */
export function decimalToHours(num: number) {
  const hours = Math.floor(num);
  const minutes = (num * 60) % 60;
  return minutes > 0 ? `${hours} hrs ${minutes} min` : `${hours} hrs`;
}

export function getTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function getNextDate(dateList: any[]) {
  const currTime = new Date();
  const validDates = dateList.filter(date => isAfter(date, currTime));
  return validDates.length === 0
    ? null
    : validDates.reduce((closestDate, currBlock) =>
        isBefore(currBlock, closestDate) ? currBlock : closestDate
      );
}
