export const RESET = "reset";
export const INCREMENT_EVENT_ID = "incrementEventId";
export const ADD_EVENT = "addEvent";
export const ADD_EVENTS = "addEvents";
export const UPDATE_EVENT = "updateEvent";
export const DELETE_EVENT = "deleteEvent";
export const SET_EVENTS = "setEvents";
export const RESERVE_ALL_EVENTS = "reserveAllEvents";
export const REMOVE_NOT_SAVED_EVENTS = "removeNotSavedEvents";
export const SET_DEFAULT_EVENT_ADDRESS = "setDefaultEventAddress";
export const REMOVE_DEFAULT_EVENT_ADDRESS = "removeDefaultEventAddress";
export const UPDATE_NOT_RESERVED_EVENTS_ADDRESS =
  "updateNotReservedEventsAddress";
export const ADD_DATE_FILTER = "addDateFilter";
export const UPDATE_MAKING_RESERVATION = "updateMakingReservation";
