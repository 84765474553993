import { captureError } from "@/plugins/error-tracking";
import Parcels from "@/services/parcels";
import * as mutation from "./mutations-types";
import { formatSendPhone } from "@zubut/common/src/utils/strings";

export function request({ dispatch, state, rootGetters }) {
  return new Promise((resolve, reject) => {
    const dest1 = state.destinations[0].data;
    const origin = {
      address: dest1.address.address,
      position: dest1.address.position,
      name: dest1.name,
      phone: formatSendPhone(dest1.phone),
      information: dest1.information
    };
    const dest2 = state.destinations[1].data;
    const destination = {
      address: dest2.address.address,
      position: dest2.address.position,
      name: dest2.name,
      email: dest2.email,
      phone: formatSendPhone(dest2.phone),
      information: dest2.information
    };
    const measures = state.measures;
    const deliveryMode = state.deliveryMode;
    const pickUpSchedule = dest1.schedule;
    const deliverySchedule = dest2.schedule;
    const additional = {
      externalId: state.additionalOptions.externalId || undefined,
      deliveryProof: state.additionalOptions.signature
    };
    const contact = {
      phone: formatSendPhone(dest2.phone)
    };
    if (dest2.email && dest2.email.length > 0) {
      contact.email = dest2.email;
    }
    const walletId = rootGetters["user/getWallet"].id;
    Parcels.request({
      parcels: [
        {
          length: measures.length,
          width: measures.width,
          height: measures.height,
          weight: measures.weight,
          origin,
          destination,
          deliveryMode,
          pickUpSchedule,
          deliverySchedule,
          additional
        }
      ],
      walletId,
      contact
    })
      .then((res, err) => {
        if (err) {
          reject(err);
          return;
        }
        dispatch("reset");
        resolve({ origin, parcel: res[0] });
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function reset({ commit }) {
  commit(mutation.RESET);
}
