import { formatSendPhone } from "@zubut/common/src/utils/strings";
import { captureError } from "@/plugins/error-tracking";
import ClientStatus from "@/constants/clients/status";
import Clients from "@/services/clients";
import * as mutation from "./mutations-types";

export function getBranchListing({}, filter) {
  return new Promise((resolve, reject) => {
    Clients.branchListing(filter)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function createBranch({ state, commit }) {
  return new Promise((resolve, reject) => {
    const formState = state.create;
    const newClient = {
      address: formState.address.address,
      email: formState.email,
      position: formState.address.position,
      password: formState.password,
      name: formState.companyName,
      contact: formState.contactName,
      phone: formatSendPhone(formState.phone),
      type: formState.type,
      information: formState.addressReferences,
      status: ClientStatus.NUM_ACTIVE,
      defaultAddress: formState.defaultAddress
    };
    const clientId = formState.parentCompany;

    Clients.createBranch(clientId, newClient)
      .then(res => {
        commit(`${mutation.RESET_CLIENT_CREATION}`);
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}
