import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("Logs");

const Logs = Object.assign(
  {},
  {
    relation: "",

    getLogs(params) {
      return loopback.get("/Logs/getUserLogs", { params });
    },

    getNotifications(params) {
      return loopback.get("/Logs/getNotifications", { params });
    }
  },
  modelObject
);

export default Logs;
