import moment from "moment";
import * as mutation from "./mutations-types";
import defaultState from "./state";

export default {
  [mutation.RESET](state) {
    const s = defaultState;
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },

  [mutation.SET_NOTIFICATIONS](state, notifications) {
    state.notifications = notifications;
  },

  [mutation.UPDATE_NOTIFICATIONS](state, notifications) {
    state.notifications = [...notifications, ...state.notifications].map(
      item => {
        item.parsedDate = moment(item.date).format("h:mm a");
        return item;
      }
    );
  },

  [mutation.ADD_NOTIFICATION](state, notification) {
    notification.parsedDate = moment(notification.date).format("h:mm a");
    state.notifications = [notification].concat(state.notifications);
  },

  [mutation.DELETE_NOTIFICATIONS](state) {
    state.notifications = [];
  },

  [mutation.UPDATE_HAS_NEW_NOTIFICATIONS](state, value) {
    state.hasNewNotifications = value;
  }
};
