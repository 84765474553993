<template>
  <b-badge class="z-badge" v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </b-badge>
</template>

<script>
export default {
  name: "ZBadge"
};
</script>

<style lang="scss" scoped>
@import "../styles/variables/colors.scss";

.z-badge {
  padding: 0.4em 0.6em !important;
  font-weight: 600 !important;
  line-height: 7px;
}

.z-badge.badge-white {
  color: $zubut-blue;
}
</style>
