import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

const getCurrentId = () => {
  const user = JSON.parse(localStorage.getItem("loopback-token"));
  if (user) {
    return user.userId;
  }
  return null;
};

modelObject.setModel("Clients");

const Clients = Object.assign(
  {},
  {
    relation: "",

    getCurrentId,

    addresses: Object.assign({}, { relation: "/addresses" }, modelObject),

    services: Object.assign({}, { relation: "/services" }, modelObject),

    billingData: Object.assign({}, { relation: "/billingData" }, modelObject),

    creditCard: Object.assign({}, { relation: "/creditCards" }, modelObject),

    edit(data) {
      const clientId = getCurrentId();
      return loopback.patch(`/Clients/${clientId}`, data);
    },

    enableSPEI() {
      const clientId = getCurrentId();
      return loopback.post(`/Clients/${clientId}/enableSPEI`);
    },

    login(data) {
      return loopback.post("/Clients/login", data);
    },

    emailRecovery(data) {
      return loopback.post("/Clients/emailRecovery", data);
    },

    passwordRecovery(secret, password) {
      return loopback.post("/Clients/passwordRecovery", { secret, password });
    },

    logout(data) {
      return loopback.post("/Clients/logout", data);
    },

    branches() {
      const clientId = getCurrentId();
      return loopback.get(`/Clients/${clientId}/branches`);
    },

    changePassword(data) {
      return loopback.post("/Clients/change-password", data);
    },
    servicesSums(data) {
      return loopback.post("/Clients/servicesSums", data);
    },
    userQuickStats(data) {
      return loopback.post("/Clients/userQuickStats", data);
    },
    dailyServiceAverage(data) {
      return loopback.post("/Clients/dailyServiceAverage", data);
    },
    averageServiceTime(data) {
      return loopback.post("/Clients/averageServiceTime", data);
    },
    getRatesOnDemand(id) {
      return loopback.get(`/Clients/${id}/ratesOndemandOrDefault`);
    },
    getRatesPackages(id) {
      return loopback.get(`/Clients/${id}/ratesPackagesOrDefault`);
    },
    getRatesReservation(id) {
      return loopback.get(`/Clients/${id}/ratesReservationOrDefault`);
    },
    getWeightedDestinations(data) {
      return loopback.post("/Clients/getWeightedDestinations", data);
    },
    getCreditCards(id) {
      return loopback.get(`/Clients/${id}/creditCards`);
    },
    userActivation(data) {
      return loopback.post("/Clients/userActivation", data);
    },
    editProfile(id, data) {
      return loopback.put(`/Clients/${id}`, data);
    },
    editUser(id, data) {
      return loopback.patch(`/Clients/${id}`, data);
    },
    updateAdditional(id, data) {
      return loopback.post(`/Clients/${id}/additional`, data);
    },
    branchListing({ filter = {}, where = {} }) {
      return loopback.get("/Clients/branchListing", {
        params: { filter, where }
      });
    },
    createBranch(id, data) {
      return loopback.post(`/Clients/${id}/createBranch`, data);
    },
    getWallletData(id, data) {
      return loopback.get(`/Clients/${id}/wallet`, data);
    },
    getCompanyWalletAmount() {
      return loopback.get(`/Clients/getCompanyWalletAmount`);
    },
    getBillingData(clientId) {
      return loopback.get(`/Clients/getBillingData`, { params: { clientId } });
    }
  },
  modelObject
);

export default Clients;
