import * as mutation from "./mutations-types";
import defaultState from "./state";
import _cloneDeep from "lodash/cloneDeep";

export default {
  /**Client creation*/
  [mutation.RESET_CLIENT_CREATION](state) {
    const s = defaultState.create;
    state.create = { ...s };
  },

  [mutation.SET_ADDRESS](state, address) {
    state.create.address = _cloneDeep(address);
  },

  [mutation.SET_ADDRESS_REFERENCES](state, addressReferences) {
    state.create.addressReferences = addressReferences;
  },

  [mutation.SET_BUSINESS_LINE](state, businessLine) {
    state.create.businessLine = businessLine;
  },

  [mutation.SET_CLIENT_TYPE](state, type) {
    state.create.type = type;
  },

  [mutation.SET_COMPANY_NAME](state, companyName) {
    state.create.companyName = companyName;
  },

  [mutation.SET_CONTACT_NAME](state, contactName) {
    state.create.contactName = contactName;
  },

  [mutation.SET_DEFAULT_ADDRESS](state, defaultAddress) {
    state.create.defaultAddress = defaultAddress;
  },

  [mutation.SET_PHONE](state, phone) {
    state.create.phone = phone;
  },

  [mutation.SET_EMAIL](state, email) {
    state.create.email = email;
  },

  [mutation.SET_PASSWORD](state, password) {
    state.create.password = password;
  },

  [mutation.SET_PARENT_COMPANY](state, parentCompany) {
    state.create.parentCompany = parentCompany;
  }
};
