export const RESET = "RESET";

export const ADD_DESTINATION = "ADD_DESTINATION";
export const DELETE_DESTINATION = "DELETE_DESTINATION";
export const UPDATE_DESTINATION = "UPDATE_DESTINATION";

export const SET_ADDITIONAL_OPTIONS_SIGNATURE =
  "SET_ADDITIONAL_OPTIONS_SIGNATURE";
export const SET_ADDITIONAL_OPTIONS_RESTRICTED_ITEMS =
  "SET_ADDITIONAL_OPTIONS_RESTRICTED_ITEMS";
export const SET_ADDITIONAL_OPTIONS_DRIVER_RETURN =
  "SET_ADDITIONAL_OPTIONS_DRIVER_RETURN";
export const SET_ADDITIONAL_OPTIONS_SCHEDULED =
  "SET_ADDITIONAL_OPTIONS_SCHEDULED";

export const SET_CARD_ID = "SET_CARD_ID";

export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD";

export const SET_RATES = "SET_RATES";
export const SET_RATES_COST = "SET_RATES_COST";
export const SET_RATES_KM = "SET_RATES_KM";
export const SET_RATES_MINUTES = "SET_RATES_MINUTES";
export const SET_VEHICLE_RATES = "SET_VEHICLE_RATES";
export const SET_RATES_CURRENCY = "SET_RATES_CURRENCY";

export const SET_VEHICLE_TYPE = "SET_VEHICLE_TYPE";

export const SET_ROUTE = "SET_ROUTE";
