const getReservationsTotalFromLocalStorage = () =>
  Number(
    localStorage.getItem("reservationsTotal") === null
      ? 0
      : localStorage.getItem("reservationsTotal")
  );

const defaultState = {
  reservations: [],
  reservationsTotal: getReservationsTotalFromLocalStorage(),
  reservationsFilter: {
    clientId: null,
    search: "",
    sortBy: "createdAt",
    sortOrder: "desc",
    vehicleType: null,
    date: null,
    alert: null,
    clientId: null,
    status: null
  },
  hasUpdates: false
};

export default defaultState;
