// Deliveries mutations
export const SET_DELIVERIES = "SET_DELIVERIES";
export const UPDATE_DELIVERIES_FILTER_TEXT = "UPDATE_DELIVERIES_FILTER_TEXT";
export const UPDATE_DELIVERIES_FILTER_CLIENT =
  "UPDATE_DELIVERIES_FILTER_CLIENT";
export const UPDATE_DELIVERIES_TOTAL = "UPDATE_DELIVERIES_TOTAL";

export const SET_FILTER_CLIENT = "setFilterClient";
export const SET_FILTER_DELIVERY_TYPE = "SET_FILTER_DELIVERY_TYPE";
export const SET_FILTER_SORT_ORDER = "setFilterSortOrder";
export const SET_FILTER_VEHICLE_TYPE = "setFilterVehicleType";
export const SET_FILTER_STATUS = "setFilterStatus";
export const SET_FILTER_SEARCH = "setFilterSearch";
export const TOGGLE_UPDATE_INDICATOR = "toggleUpdateIndicator";
