import { captureError } from "@/plugins/error-tracking";
import * as _l from "lodash/lang";
import Clients from "@/services/clients";
import store from "@/store";
import * as mutation from "./mutations-types";

export function reconnectServer({ commit }) {
  console.log("reconnectServer");
  commit(mutation.UPDATE_IS_RECONNECTING, true);
  let counter = 0;

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const reconnectInterval = setInterval(() => {
        Clients.findById({ id: `${Clients.getCurrentId()}` })
          .then(() => {
            console.log("connection is back");
            commit(mutation.UPDATE_IS_RECONNECTING, false);
            clearInterval(reconnectInterval);
            resolve();
          })
          .catch(err => {
            captureError(err);
            console.log("looking for connection try", counter);
            counter++;
            if (counter > 10) {
              console.log("reconnect failed");
              store.dispatch("auth/logoutSync");
              commit(mutation.UPDATE_IS_RECONNECTING, false);
              clearInterval(reconnectInterval);
              reject();
            }
          });
      }, 5000);
    }, 500);
  });
}

export function updateConnectState({ commit }, connect) {
  commit(mutation.UPDATE_CONNECT_STATE, connect);
}
