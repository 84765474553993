export default {
  // Deliveries getters
  getDeliveries(state) {
    return state.deliveries;
  },
  getDeliveriesTotal(state) {
    return state.deliveriesTotal;
  },
  getDeliveriesFilters(state) {
    return state.deliveriesFilter;
  },
  getDeliveriesFilterText(state) {
    return state.deliveriesFilter.text;
  },
  getDeliveriesFilterClient(state) {
    return state.deliveriesFilter.clientId;
  },
  // Sockets
  getHasUpdates(state) {
    return state.hasUpdates;
  }
};
