import * as mutation from "./mutations-types";
import defaultState from "./state";

export default {
  [mutation.RESET](state) {
    const s = defaultState;
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },

  // Destination creation
  [mutation.ADD_DESTINATION](state, destination) {
    state.destinations = [...state.destinations, { ...destination }];
  },

  [mutation.DELETE_DESTINATION](state, index) {
    state.destinations = state.destinations.filter((el, i) => i !== index);
  },

  [mutation.UPDATE_DESTINATION](state, destination) {
    state.destinations = state.destinations.map((item, i) => {
      if (destination.index === i) {
        return { ...destination };
      }
      return item;
    });
  },

  [mutation.SET_ADDITIONAL_OPTIONS_DRIVER_RETURN](state, driverReturn) {
    state.additionalOptions.driverReturn = driverReturn;
  },

  [mutation.SET_ADDITIONAL_OPTIONS_SCHEDULED](state, scheduledDate) {
    state.additionalOptions.scheduled = scheduledDate;
  },

  [mutation.SET_ADDITIONAL_OPTIONS_SIGNATURE](state, signature) {
    state.additionalOptions.signature = signature;
  },

  [mutation.SET_ADDITIONAL_OPTIONS_RESTRICTED_ITEMS](state, restrictedItems) {
    state.additionalOptions.restrictedItems = restrictedItems;
  },

  [mutation.SET_CARD_ID](state, cardId) {
    state.cardId = cardId;
  },

  [mutation.SET_PAYMENT_METHOD](state, paymentMethod) {
    state.paymentMethod = paymentMethod;
  },

  [mutation.SET_RATES](state, rates) {
    state.rates = rates;
  },

  [mutation.SET_RATES_COST](state, cost) {
    state.rates.cost = cost;
  },

  [mutation.SET_RATES_KM](state, km) {
    state.rates.km = km;
  },

  [mutation.SET_RATES_MINUTES](state, minutes) {
    state.rates.minutes = minutes;
  },

  [mutation.SET_VEHICLE_RATES](state, vehicleRates) {
    state.vehicleRates = vehicleRates;
  },

  [mutation.SET_RATES_CURRENCY](state, currency) {
    state.rates.currency = currency;
  },

  [mutation.SET_VEHICLE_TYPE](state, vehicleType) {
    state.vehicleType = vehicleType;
  }
};
