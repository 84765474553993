export const RESET = "reset";
export const UPDATE_USER = "updateUser";
export const UPDATE_BILLING_DATA = "updateBillingData";

export const ADD_CREDIT_CARD = "addCreditCard";
export const DELETE_CREDIT_CARD = "deleteCreditCard";
export const DELETE_ALL_CREDIT_CARDS = "deleteAllCreditCards";
export const UPDATE_DEFAULT_CREDIT_CARD = "updateDefaultCreditCard";
export const CLEAR_DEFAULT_CREDIT_CARD = "clearDefaultCreditCard";

export const ADD_ADDRESS = "addAddress";
export const UPDATE_ADDRESS = "updateAddress";
export const DELETE_ADDRESS = "deleteAddress";
export const DELETE_ALL_ADDRESS = "deleteAllAddress";
export const UPDATE_DEFAULT_ADDRESS = "updateDefaultAddress";
export const CLEAR_DEFAULT_ADDRESS = "clearDefaultAddress";

export const UPDATE_WALLET = "updateWallet";
export const DELETE_WALLET_PENDING = "deleteWalletPending";

export const UPDATE_USER_ADDITIONAL = "updateUserAdditional";

export const UPDATE_SESSION_POSITION = "updateSessionPosition";

export const UPDATE_ENABLED_SERVICES = "updateEnabledServices";
