import { captureError } from "@/plugins/error-tracking";
import Services from "@/services/services";
import Type from "@zubut/common/src/constants/services/type";
import Http from "@zubut/common/src/constants/http";
import * as mutation from "./mutations-types";

export function reset({ commit, state }) {
  return new Promise(resolve => {
    commit(mutation.RESET);
    resolve(state);
  });
}

export function updateDriverPosition() {
  // Do nothing if the service is not in progress
}

export function getServiceListing(_, filter) {
  return new Promise((resolve, reject) => {
    Services.listing(filter)
      .then(({ data: services, meta }) => {
        resolve({ data: services, meta });
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function connected(_, data) {
  console.log("respuesta: ", data);
}

export function cancel(_, data) {
  return new Promise((resolve, reject) => {
    Services.cancel(data)
      .then(res => {
        if (res && res.response) {
          resolve(res);
        } else {
          reject();
        }
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function serviceActionUpdate({ commit }, data) {
  if (data.serviceId && data.driverId) {
    if (
      Type.isMultiPoint(data.serviceType) ||
      Type.isParcel(data.serviceType) ||
      Type.isPackageService(data.serviceType)
    ) {
      commit("dashboard/deliveries/toggleUpdateIndicator", true);
    } else {
      // Reservations
      commit("dashboard/reservations/toggleUpdateIndicator", true);
    }
  }
}

export function serviceFindOne(_, clientId) {
  const filter = {
    order: "createdAt DESC",
    where: {
      clientId
    },
    limit: 1
  };
  return new Promise((resolve, reject) => {
    Services.get({ filter })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function getDetail(_, id) {
  return new Promise((resolve, reject) => {
    Services.getDetails(id)
      .then(({ service }) => {
        resolve(service);
      })
      .catch(err => {
        reject(err);
        captureError(err);
      });
  });
}

export function createInvoice(_, id) {
  return new Promise((resolve, reject) => {
    Services.createInvoice({ serviceId: id })
      .then(() => {
        resolve();
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

const definePayError = err => {
  let error = null;
  if (err.statusCode === Http.BAD_REQUEST) {
    if (err.message == "Service has incidence")
      error = new Error("El servicio tiene incidencias");
    else {
      error = new Error("El servicio tiene status incorrecto o está pagado");
    }
  }
  if (err.statusCode === Http.NOT_FOUND) {
    error = new Error("No se encontró el servicio");
  }
  if (err.statusCode === Http.SERVER_ERROR) {
    error = new Error("Error interno al realizar cobro");
  }
  if (err.statusCode == null) {
    error = new Error("Error desconocido, contacta al equipo técnico");
  }
  // fail safe
  if (error == null) error = err;
  return error;
};

export function payService(_, data) {
  return new Promise((resolve, reject) => {
    Services.pay(data)
      .then(res => {
        if (res.data && res.data.status === Http.SUCCESS) {
          resolve(res);
        } else {
          let error = definePayError(res);
          reject(error);
        }
      })
      .catch(err => {
        captureError(err);
        let error = definePayError(err);
        reject(error);
      });
  });
}
