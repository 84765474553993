import Geonames from "geonames.js";
import { captureError } from "@/plugins/error-tracking";

export function getNeighborhoodByZipCode({}, zipcode) {
  const username = process.env.VUE_APP_GEONAMES_USER;
  return new Promise((resolve, reject) => {
    const geonames = new Geonames({
      username: username,
      lan: "es",
      encoding: "JSON"
    });
    geonames
      .postalCodeLookup({ postalcode: zipcode, country: "MX" })
      .then(res => {
        if (res.postalcodes == null || res.postalcodes.length == 0) {
          reject(new Error("Error al obtener datos suficientes"));
        }
        const state = res.postalcodes[0].adminName1;
        const city = res.postalcodes[0].adminName2;
        const neighborhoods = [
          ...new Set(res.postalcodes.map(obj => obj.placeName))
        ];
        if (state && city && neighborhoods.length > 0) {
          const data = {
            state,
            city,
            neighborhoods
          };
          resolve(data);
        } else {
          reject(new Error("Error al obtener datos suficientes"));
        }
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}
