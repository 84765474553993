var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
    'z-button',
    { disabled: _vm.disabled },
    _vm.variantClass,
    _vm.textColorClass,
    { 'z-loading': _vm.loading },
    { expanded: _vm.expanded },
    { 'size-sm': _vm.size === 'sm' },
    { 'size-md': _vm.size === 'md' },
    { 'size-lg': _vm.size === 'lg' },
    { 'fit-normal': _vm.fit === 'normal' },
    { 'fit-auto': _vm.fit === 'auto' }
  ],attrs:{"disabled":_vm.disabled,"type":_vm.type},on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"loading-spinner"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }