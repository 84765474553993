import Logo from "../assets/img/zubut-white.png";

class BrowserNotification {
  options = {
    vibrate: [200, 100, 200],
    requireInteraction: false,
    icon: Logo,
    body: ""
  };

  config = {
    title: "Nueva notificación",
    timeout: 5000
  };

  async getRegistration() {
    return navigator.serviceWorker.ready;
  }

  async closeNotifications(timeout = 0) {
    const registration = await this.getRegistration();
    const notifications = await registration.getNotifications();
    setTimeout(() => {
      notifications.map(notification => notification.close());
    }, timeout);
  }

  requestNotificationPermissions = () => {
    if (this.hasNotificationSupport()) {
      const notificationPermissions = async () => {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          await this.closeNotifications();
          const registration = await this.getRegistration();
          await registration.showNotification(this.config.title, this.options);
          await this.closeNotifications(this.config.timeout);
        }
        if (permission === "denied") {
          alert(
            "Notifications are blocked. Please enable them in your browser settings."
          );
        }
      };
      notificationPermissions();
    }
  };

  sendNotification = () => {
    if (this.hasNotificationSupport()) {
      const send = async () => {
        if (Notification.permission === "granted") {
          await this.closeNotifications();
          const registration = await this.getRegistration();
          await registration.showNotification(this.config.title, this.options);
          await this.closeNotifications(this.config.timeout);
        }
      };
      send();
    }
  };

  /* Check if the browser supports the Notifications API. iOS currently doesn't support it */
  hasNotificationSupport() {
    return window.Notification !== undefined;
  }
}

export default BrowserNotification;
