import * as mutation from "./mutations-types";

export default {
  [mutation.SET_CITIES](state, cities) {
    state.cities = [...cities];
  },

  [mutation.SET_SELECTED_CITY](state, city) {
    state.selectedCity = city;
  }
};
