<template>
  <b-tabs
    class="z-tabs"
    v-bind="$attrs"
    v-on="$listeners"
    :fill="responsiveComputed"
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </b-tabs>
</template>

<script>
export default {
  name: "ZTabs",

  props: {
    responsive: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    responsiveComputed() {
      if (this.windowWidth >= 768 && this.responsive) {
        return false;
      }
      return this.responsive;
    }
  }
};
</script>

<style lang="scss">
@import "../styles/variables/colors.scss";

.z-tabs .nav-pills {
  position: relative;
  padding-bottom: 1em;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $link-water;
    bottom: 0px;
    left: 0px;
    z-index: 1;
  }
}
</style>
