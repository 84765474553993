import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("CreditCards");

const creditCards = Object.assign(
  {},
  {
    relation: "",

    validate(data) {
      return loopback.post("/CreditCards/validate", { data });
    },

    setDefault(data) {
      return loopback.post("/CreditCards/setDefault", { data });
    }
  },
  modelObject
);

export default creditCards;
