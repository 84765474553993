/* eslint-disable */
import loopback from '@/services/loopback';
import modelObject from '@/services/lb.services';

modelObject.setModel('CSVContainers');

let CSVContainers = Object.assign({}, {
    generateDrivers(where) {
        return loopback.post('/CSVContainers/generateDrivers', where, { responseType: 'blob' });
    },
    generateServices(where) {
        return loopback.post('/CSVContainers/generateServices', where, { responseType: 'blob' });
    },
    generateUsers(where) {
        return loopback.post('/CSVContainers/generateUsers', where, { responseType: 'blob' });
    }
}, modelObject);

export default CSVContainers;