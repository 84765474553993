export default {
  branchesOptions(state) {
    return [{ text: "Todas", value: null }].concat(
      state.branches.map(branch => ({
        value: branch.id,
        text: `${branch.name}`
      }))
    );
  }
};
