import * as mutation from "./mutations-types";

export default {
  [mutation.SET_RESERVATIONS](state, reservations) {
    state.reservations = [...reservations];
  },

  [mutation.UPDATE_RESERVATIONS_TOTAL](state, total) {
    state.reservationsTotal = total;
  },

  [mutation.SET_FILTER_DATE](state, date) {
    state.reservationsFilter.date = date;
  },

  [mutation.SET_FILTER_SEARCH](state, search) {
    state.reservationsFilter.search = search;
  },

  [mutation.SET_FILTER_VEHICLE_TYPE](state, vehicleType) {
    state.reservationsFilter.vehicleType = vehicleType;
  },

  [mutation.SET_FILTER_CLIENT](state, clientId) {
    state.reservationsFilter.clientId = clientId;
  },

  [mutation.SET_FILTER_STATUS](state, status) {
    state.reservationsFilter.status = status;
  },

  [mutation.TOGGLE_UPDATE_INDICATOR](state, hasUpdates) {
    state.hasUpdates = hasUpdates;
  }
};
