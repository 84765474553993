import ServiceType from "@zubut/common/src/constants/services/type";
import { formatMoney } from "@/utils/money";
import ClientBusinessLine from "@/constants/clients/businessLine";
import ClientTypes from "@zubut/common/src/constants/clients/type";

// User
export function getUserId(state) {
  return state.id;
}

export function getUserType(state) {
  return state.userType;
}

export function getType(state) {
  return state.type;
}

// Clien types
export function isCompany(state) {
  return state.type === ClientTypes.NUM_COMPANY;
}

export function isBranch(state) {
  return state.type === ClientTypes.NUM_BRANCH;
}

export function clientTypeText(state, getters) {
  return ClientTypes.get[getters.getType];
}

// TODO: Remove use of managers
export function isManager() {
  return false;
}

export function isRestaurant(state) {
  return state.businessLine === ClientBusinessLine.NUM_RESTAURANT;
}

export function isUser() {
  return true;
}

export function isConsolidatedBilling(state) {
  if (state.consolidatedBilling) {
    return state.consolidatedBilling;
  }
  return false;
}

export function isConsolidatedUser(state, getters) {
  if (getters.isConsolidatedBilling) {
    return true;
  }

  // If have accounts
  if (state.account && state.account.length > 0) {
    return true;
  }

  return false;
}

export function isAccountUser(state) {
  return state.account.length > 0;
}

export function getBillingData(state) {
  return state.billingData;
}

// Addresses
export function getAddresses(state) {
  return state.addresses;
}

export function getAvailableAddresses(state) {
  return state.addresses.filter(address => !address.defaultOrigin);
}

export function getDefaultAddress(state) {
  return state.addresses.filter(
    address => address.defaultOrigin === true && address.clientId === state.id
  );
}

export function getDefaultAddressParsed(state, getters) {
  const defaultAddress = getters["getDefaultAddress"][0];
  return defaultAddress
    ? {
        index: 0,
        data: {
          id: defaultAddress.id,
          address: {
            address: defaultAddress.address,
            position: defaultAddress.position
          },
          phone: defaultAddress.phone || "",
          name: defaultAddress.name || "",
          email: defaultAddress.email || "",
          information: defaultAddress.information || ""
        }
      }
    : null;
}

// Credit cards
export function getCreditCards(state) {
  if (state.creditCards) {
    return state.creditCards;
  }
  return [];
}

export function getDefaultCreditCard(state) {
  if (state.creditCards && state.creditCards.length > 0) {
    return state.creditCards.find(creditCard => creditCard.default);
  }
  return null;
}

// Enabled services
export function getEnabledServices(state) {
  return state.enabledServices || [];
}

export function isExpressServiceEnabled(state, getters) {
  return (
    getters.getEnabledServices.findIndex(type =>
      ServiceType.isMultiPoint(type)
    ) > -1
  );
}

export function isParcelServiceEnabled(state, getters) {
  return (
    getters.getEnabledServices.findIndex(type => ServiceType.isParcel(type)) >
    -1
  );
}

export function isServiceTypeEnabled(state, getters) {
  return type => getters.getEnabledServices.findIndex(t => t === type) > -1;
}

// Wallet
export function getWallet(state) {
  if (state.wallet) {
    return state.wallet;
  }
  return null;
}

export function hasWallet(state) {
  if (state.wallet && state.wallet.id) {
    return true;
  }
  return false;
}

export function getWalletAmount(state) {
  const user = state;
  return user?.wallet?.amount || 0;
}

export function getWalletBalance(state) {
  const user = state;
  if (user?.wallet?.amount) {
    return `${formatMoney(user.wallet.amount / 100)}  `;
  }
  return `${formatMoney(0)}  `;
}

export function getWalletPaymentMethod(state) {
  const user = state;
  if (!user || !user.wallet) return "spei";

  if (user.wallet.clabe) {
    return "spei";
  } else if (user.wallet.reference) {
    return "oxxo";
  }
  return "";
}

export function getWalletPendingStatus(state) {
  const user = state;
  if (!user || !user.wallet) return "";

  const wallet = user.wallet;
  if (
    wallet.pending &&
    (wallet.pending === true || wallet.pending.length > 0)
  ) {
    return true;
  }
  return false;
}

export function getClabeReference(state) {
  return state.wallet?.clabeReference || "";
}

// Session position
export function getSessionPosition(state) {
  return state.sessionPosition;
}
