import { captureError } from "@/plugins/error-tracking";
import Reservations from "@/services/reservations";
import Services from "@/services/services";
import * as mutation from "./mutations-types";

export function getReservations({ commit }, request) {
  return new Promise((resolve, reject) => {
    Reservations.services(request)
      .then(({ data, meta }) => {
        commit(mutation.SET_RESERVATIONS, data);
        if (meta && meta.skip === 0) {
          commit(mutation.UPDATE_RESERVATIONS_TOTAL, meta.count);
          localStorage.setItem("reservationsTotal", meta.count);
        }
        resolve({ data, meta });
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function cancel(_, data) {
  return new Promise((resolve, reject) => {
    Services.cancel({
      id: data.id
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}
