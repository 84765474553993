export const RESET = "RESET";

export const UPDATE_DESTINATION = "UPDATE_DESTINATION";

export const UPDATE_MEASURES = "UPDATE_MEASURES";

export const UPDATE_DELIVERY_MODE = "UPDATE_DELIVERY_MODE";

export const SET_ADDITIONAL_OPTIONS_EXTERNAL_ID =
  "SET_ADDITIONAL_OPTIONS_EXTERNAL_ID";

export const SET_ADDITIONAL_OPTIONS_SIGNATURE =
  "SET_ADDITIONAL_OPTIONS_SIGNATURE";

export const SET_ROUTE = "SET_ROUTE";
