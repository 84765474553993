interface TimeZone {
  u?: number;
  d?: number;
  c: string[];
}

interface TimeZones {
  [key: string]: TimeZone;
}

const getCountry = (): { name: string; code: string } => {
  const countries: { [key: string]: string } = {
    CO: "Colombia",
    MX: "Mexico"
  };
  const timezones: TimeZones = {
    "America/Bahia_Banderas": {
      u: -360,
      d: -300,
      c: ["MX"]
    },
    "America/Bogota": {
      u: -300,
      c: ["CO"]
    },
    "America/Cancun": {
      u: -300,
      c: ["MX"]
    },
    "America/Chihuahua": {
      u: -420,
      d: -360,
      c: ["MX"]
    },
    "America/Hermosillo": {
      u: -420,
      c: ["MX"]
    },
    "America/Matamoros": {
      u: -360,
      d: -300,
      c: ["MX"]
    },
    "America/Mazatlan": {
      u: -420,
      d: -360,
      c: ["MX"]
    },
    "America/Merida": {
      u: -360,
      d: -300,
      c: ["MX"]
    },
    "America/Mexico_City": {
      u: -360,
      d: -300,
      c: ["MX"]
    },
    "America/Monterrey": {
      u: -360,
      d: -300,
      c: ["MX"]
    },
    "America/Ojinaga": {
      u: -420,
      d: -360,
      c: ["MX"]
    },
    "America/Tijuana": {
      u: -480,
      d: -420,
      c: ["MX"]
    }
  };

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (timezone === "" || !timezone) {
    return { name: "Mexico", code: "MX" };
  }

  const countryCode = timezones[timezone].c[0];
  const country = countries[countryCode];
  return { name: country, code: countryCode };
};

export default {
  getCountry
};
