import DropdownOption from "@zubut/common/src/models/dropdown-option";

const MOTORCYCLE = "Motocicleta";
const CAR = "Automóvil";
const ECO = "Eco";
const TRUCK = "Camión";

const NUM_MOTORCYCLE = 0;
const NUM_CAR = 1;
const NUM_ECO = 2;
const NUM_TRUCK = 3; // Not a real type yet

const get: any = {};
get[NUM_MOTORCYCLE] = MOTORCYCLE;
get[NUM_CAR] = CAR;
get[NUM_ECO] = ECO;
get[NUM_TRUCK] = TRUCK;

const options: DropdownOption[] = [
  { value: NUM_MOTORCYCLE, text: MOTORCYCLE },
  { value: NUM_CAR, text: CAR },
  { value: NUM_ECO, text: ECO }
];

export default {
  get,
  options,
  MOTORCYCLE,
  CAR,
  ECO,
  TRUCK,
  NUM_MOTORCYCLE,
  NUM_CAR,
  NUM_ECO,
  NUM_TRUCK
};
