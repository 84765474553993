import { captureError } from "@/plugins/error-tracking";
import Wallets from "@/services/wallets";

export function createOxxoOrder({}, { clientId, charge }) {
  return new Promise((resolve, reject) => {
    Wallets.createOxxoOrder({ clientId, charge })
      .then(({ response }) => {
        const { id, reference, amount } = response;
        resolve({ id, reference, amount });
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function createSpeiOrder({}, { clientId, charge }) {
  return new Promise((resolve, reject) => {
    Wallets.createSPEIOrder({ clientId, charge })
      .then(({ response }) => {
        const { id, clabe, amount } = response;
        resolve({ id, clabe, amount });
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function cancelOrder({}, { clientId }) {
  return new Promise((resolve, reject) => {
    Wallets.cancelOrder({ clientId })
      .then(() => {
        resolve();
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}
