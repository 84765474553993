const SIMPLE = "Sencillo";
const MULTI_POINT = "Express";
const RENT_PER_HOUR = "Dedicados";
const DYNAMIC = "Dinámico";
const PACKAGE_DELIVERY = "Entrega de paquetes";
const BATCH = "Lotes";
const PARCEL_DELIVERY = "Guías envio";
const PARCEL_PICKUP = "Guías envio";
const PARCEL = "Guías";

const NUM_SIMPLE = 0;
const NUM_MULTI_POINT = 1;
const NUM_RENT_PER_HOUR = 2;
const NUM_DYNAMIC = 3;
const NUM_PACKAGE_DELIVERY = 4;
const NUM_BATCH = 5;
const NUM_PARCEL_DELIVERY = 6;
const NUM_PARCEL_PICKUP = 7;
// This constant includes the creation of parcels.
// The validation of the creation of parcels is done by the recollection services or Parcel Pickup
const NUM_PARCEL = 7;

const get: any = {};
get[NUM_SIMPLE] = MULTI_POINT;
get[NUM_MULTI_POINT] = MULTI_POINT;
get[NUM_RENT_PER_HOUR] = RENT_PER_HOUR;
get[NUM_DYNAMIC] = MULTI_POINT;
get[NUM_PACKAGE_DELIVERY] = PACKAGE_DELIVERY;
get[NUM_BATCH] = BATCH;
get[NUM_PARCEL_DELIVERY] = PARCEL_DELIVERY;
get[NUM_PARCEL_PICKUP] = PARCEL_PICKUP;

const mulitPointTypes = [NUM_SIMPLE, NUM_MULTI_POINT, NUM_DYNAMIC];
const packageTypes = [NUM_PACKAGE_DELIVERY, NUM_BATCH];
const parcelTypes = [NUM_PARCEL, NUM_PARCEL_PICKUP, NUM_PARCEL_DELIVERY];

const isMultiPoint = (val: number) => {
  return mulitPointTypes.indexOf(val) > -1;
};

const isPackageService = (val: number) => {
  return packageTypes.indexOf(val) > -1;
};

const isParcel = (val: number) => {
  return parcelTypes.indexOf(val) > -1;
};

const isReservation = (val: number) => {
  return NUM_RENT_PER_HOUR === val;
};

const options = [
  { value: NUM_MULTI_POINT, text: MULTI_POINT },
  { value: NUM_RENT_PER_HOUR, text: RENT_PER_HOUR },
  { value: NUM_PARCEL, text: PARCEL },
  { value: NUM_BATCH, text: BATCH }
];

const validator = (val: number) => {
  return (
    [
      NUM_SIMPLE,
      NUM_MULTI_POINT,
      NUM_RENT_PER_HOUR,
      NUM_DYNAMIC,
      NUM_PACKAGE_DELIVERY,
      NUM_BATCH,
      NUM_PARCEL_DELIVERY,
      NUM_PARCEL_PICKUP,
      NUM_PARCEL
    ].indexOf(val) > -1
  );
};

export default {
  get,
  options,
  validator,
  isMultiPoint,
  isPackageService,
  isParcel,
  isReservation,
  mulitPointTypes,
  packageTypes,
  parcelTypes,
  SIMPLE,
  MULTI_POINT,
  RENT_PER_HOUR,
  DYNAMIC,
  PACKAGE_DELIVERY,
  BATCH,
  PARCEL,
  PARCEL_DELIVERY,
  PARCEL_PICKUP,
  NUM_SIMPLE,
  NUM_MULTI_POINT,
  NUM_RENT_PER_HOUR,
  NUM_DYNAMIC,
  NUM_PACKAGE_DELIVERY,
  NUM_BATCH,
  NUM_PARCEL_DELIVERY,
  NUM_PARCEL_PICKUP,
  NUM_PARCEL
};
