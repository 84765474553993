import { captureError } from "@/plugins/error-tracking";
import isEqual from "lodash/isEqual";
import isSameMinute from "date-fns/isSameMinute";
import parseISO from "date-fns/parseISO";
import { formatToISO } from "@zubut/common/src/utils/time";
import Reservations from "@/services/reservations";
import cloneDeep from "lodash/cloneDeep";
import * as mutation from "./mutation-types";

export async function updateEvents({ state, commit }, event) {
  try {
    const eventOverlaps = _ev => {
      const eventsBetween = state.events.filter(ev => {
        if (!ev.reserved && ev.id !== event.id) {
          const startIsSame = isSameMinute(
            parseISO(_ev.start),
            parseISO(ev.start)
          );
          const endIsSame = isSameMinute(parseISO(_ev.end), parseISO(ev.end));
          let newDrivers = 0;
          if (_ev.drivers) {
            newDrivers += _ev.drivers;
          }
          if (ev.drivers) {
            newDrivers += ev.drivers;
          }
          const validDriverCount = newDrivers <= state.maxDrivers;
          if (startIsSame && endIsSame && validDriverCount) {
            return true;
          }
        }
        return false;
      });
      return eventsBetween || false;
    };

    const mergeEvents = (evA, evB) => {
      const evAStart = formatToISO(parseISO(evA.start));
      const evAEnd = formatToISO(parseISO(evA.end));
      let newDrivers = 0;
      if (evA.drivers) {
        newDrivers += evA.drivers;
      }
      if (evB.drivers) {
        newDrivers += evB.drivers;
      }
      return { ...evA, start: evAStart, end: evAEnd, drivers: newDrivers };
    };

    const eventIndex = state.events.findIndex(ev => ev.id === event.id);
    const eventsOverlaped = eventOverlaps(event);

    if (eventsOverlaped.length > 0) {
      let mergedEvent = cloneDeep(event);
      eventsOverlaped.forEach(ev => {
        commit(mutation.DELETE_EVENT, mergedEvent);
        mergedEvent = mergeEvents(ev, mergedEvent);
      });
      commit(mutation.UPDATE_EVENT, mergedEvent);
    } else if (eventIndex === -1) {
      commit(mutation.ADD_EVENT, event);
      if (event.address && state.defaultAddress === null) {
        commit(mutation.SET_DEFAULT_EVENT_ADDRESS, {
          ...event.address,
          id: null
        });
      }
    } else if (isEqual(event.address, state.defaultAddress)) {
      commit(mutation.UPDATE_EVENT, event);
    } else {
      commit(mutation.UPDATE_EVENT, event);
      commit(mutation.SET_DEFAULT_EVENT_ADDRESS, {
        ...event.address,
        id: null
      });
      commit(mutation.UPDATE_NOT_RESERVED_EVENTS_ADDRESS);
    }
  } catch (err) {
    captureError(err);
    throw new Error(err);
  }
}

export function deleteEvent({ commit }, event) {
  return new Promise(resolve => {
    commit(mutation.DELETE_EVENT, event);
    resolve();
  });
}

export function reserveAllEvents(
  { commit, getters, state, rootState },
  { vehicleType }
) {
  return new Promise((resolve, reject) => {
    commit(mutation.UPDATE_MAKING_RESERVATION, true);
    const createRequestData = () => {
      const information = state.defaultAddress.information || "Sin especificar";
      const origin = { ...state.defaultAddress, information };
      const reservationHours = getters.getNonReservedEvents.map(item => {
        return {
          start: item.start,
          end: item.end,
          driverNo: item.drivers
        };
      });
      const clientId = rootState.user.id;
      return { origin, reservationHours, clientId, vehicleType };
    };
    Reservations.generate(createRequestData())
      .then(() => {
        commit(mutation.RESERVE_ALL_EVENTS);
        commit(mutation.REMOVE_DEFAULT_EVENT_ADDRESS);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      })
      .finally(() => {
        commit(mutation.UPDATE_MAKING_RESERVATION, false);
        resolve();
      });
  });
}

export function removeNotSavedEvents({ commit }) {
  return new Promise(resolve => {
    commit(mutation.REMOVE_NOT_SAVED_EVENTS);
    resolve();
  });
}

export function getReservations({ commit, state }, { where }) {
  return new Promise((resolve, reject) => {
    Reservations.listing({ where })
      .then(res => {
        let events = [];
        for (const reservation of res.data) {
          const address = reservation.origin;
          for (let reservationBlock of reservation.reservationBlocks) {
            reservationBlock.end = formatToISO(parseISO(reservationBlock.end));
            reservationBlock.start = formatToISO(
              parseISO(reservationBlock.start)
            );
            reservationBlock.address = address;
            reservationBlock.reserved = true;
            reservationBlock.title = reservationBlock.address.address;
            reservationBlock.editable = false;
            reservationBlock.saved = true;
            reservationBlock.drivers = reservationBlock.driverNo;

            events.push(reservationBlock);
          }
        }

        // This validates that events that already exist in the store
        // are no added again.
        const parsedEvents = events.filter(event => {
          // The data type of the item.id is checked because the events
          // are saved with a temporary id (of type number) before reserving them,
          // this way they are not added again to the store.
          const eventExists = state.events.find(
            item => event.id === item.id || typeof item.id === "number"
          );
          if (eventExists) {
            return false;
          }
          return true;
        });
        commit(mutation.SET_EVENTS, parsedEvents);
        commit(mutation.ADD_DATE_FILTER, {
          start: where.start,
          end: where.end
        });
        resolve();
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function rateReservation(_, { totalHours, clientId, vehicleType }) {
  return new Promise((resolve, reject) => {
    Reservations.calculateHourlyRate({ totalHours, clientId, vehicleType })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function getReservationListing({}) {
  return new Promise((resolve, reject) => {
    Reservations.listing()
      .then(({ data: reservations, meta }) => {
        resolve({ reservations, meta });
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function fetchLastReservation({ state, commit }, reservationWeek) {
  return new Promise((resolve, reject) => {
    Reservations.lastReservation(reservationWeek.format())
      .then(reservation => {
        const events = reservation.reservationBlocks.map(block => {
          const event = {
            ...block,
            id: state.eventId,
            end: formatToISO(parseISO(block.end)),
            start: formatToISO(parseISO(block.start)),
            address: reservation.origin,
            reserved: false,
            saved: true,
            fromQuickReservation: true,
            drivers: block.driverNo
          };
          commit(mutation.INCREMENT_EVENT_ID);
          return event;
        });
        commit(mutation.ADD_EVENTS, events);
        if (
          events.length > 0 &&
          events[0].address &&
          state.defaultAddress === null
        ) {
          commit(mutation.SET_DEFAULT_EVENT_ADDRESS, {
            ...events[0].address,
            id: null
          });
        }
        resolve();
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}
