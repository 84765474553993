import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("Wallets");

const Wallets = Object.assign(
  {},
  {
    relation: "",

    user: Object.assign({}, { relation: "/user" }, modelObject),

    createOxxoOrder(data) {
      return loopback.post("/Wallets/createOxxoOrder", data);
    },

    createSPEIOrder(data) {
      return loopback.post("/Wallets/createSPEIOrder", data);
    },

    cancelOrder(data) {
      return loopback.post("/Wallets/cancelOrder", data);
    }
  },
  modelObject
);

export default Wallets;
