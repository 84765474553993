export default {
  getOriginAddress(state) {
    return state.destinations.find(el => el.origin === true);
  },

  getAddress(state) {
    return index => state.destinations[index];
  },

  getDefaultAddressParsed(state, getters, rootState, rootGetters) {
    const defaultAddress = rootGetters["user/getDefaultAddress"][0];
    return defaultAddress
      ? {
          index: 0,
          data: {
            id: defaultAddress.id,
            address: {
              address: defaultAddress.address,
              position: defaultAddress.position
            },
            phone: defaultAddress.phone || "",
            name: defaultAddress.name || "",
            email: defaultAddress.email || "",
            information: defaultAddress.information || ""
          }
        }
      : null;
  },

  getAdditionalOptions(state) {
    return state.additionalOptions;
  },

  getDestinations(state) {
    return state.destinations;
  },

  getMeasures(state) {
    return state.measures;
  },

  getDeliveryMode(state) {
    return state.deliveryMode;
  },

  hasInvalidDestinations(state) {
    const hasInvalidDestination = state.destinations.find(
      d => !d.data?.address?.address || !d.data?.address?.position
    );
    return hasInvalidDestination;
  },

  getMapDestinations(state) {
    return state.destinations
      .filter(d => d.data.address?.address && d.data.address?.position)
      .map(d => ({
        order: d.index,
        name: d.data.name,
        address: d.data.address?.address,
        email: d.data.email,
        position: d.data.address?.position
      }));
  },

  isFormValid(state, getters, rootState, rootGetters) {
    const hasInvalidDestination = state.destinations.find(
      d => !d.data?.address?.address || !d.data?.address?.position
    );
    const hasMesuares = state.measures.valid;
    if (!hasInvalidDestination && hasMesuares) {
      return true;
    }
    return false;
  }
};
