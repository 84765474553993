export default {
  getOriginAddress(state) {
    return state.destinations.find(el => el.origin === true);
  },

  getAddress(state) {
    return index => state.destinations[index];
  },

  getDefaultAddressParsed(state, getters, rootState, rootGetters) {
    const defaultAddress = rootGetters["user/getDefaultAddress"][0];
    return defaultAddress
      ? {
          index: 0,
          data: {
            id: defaultAddress.id,
            address: {
              address: defaultAddress.address,
              position: defaultAddress.position
            },
            phone: defaultAddress.phone || "",
            name: defaultAddress.name || "",
            information: defaultAddress.information || ""
          }
        }
      : null;
  },

  getAdditionalOptions(state) {
    return state.additionalOptions;
  },

  getDestinations(state) {
    return state.destinations;
  },

  getMapDestinations(state) {
    return state.destinations
      .filter(d => d.data.address?.address && d.data.address?.position)
      .map(d => ({
        order: d.index,
        name: d.data.name,
        address: d.data.address?.address,
        position: d.data.address?.position
      }));
  },

  getVehicleRates(state) {
    return {
      Motorcycle: state.vehicleRates.bikeKMCost || -1,
      Car: state.vehicleRates.carKMCost || -1,
      Eco: state.vehicleRates.ecoKMCost || -1
    };
  },

  isFormValid(state, getters, rootState, rootGetters) {
    const hasInvalidDestination = state.destinations.find(
      d => !d.data?.address?.address || !d.data?.address?.position
    );
    const hasSelectedCard = state.paymentMethod === "card" && state.cardId;
    const walletAmount = rootGetters["user/getWalletAmount"];
    const walletId = rootGetters["user/getWallet"]?.id;
    const hasWalletMethod =
      state.paymentMethod === "wallet" && walletAmount && walletId;
    const hasSelectedPaymentMethod = hasWalletMethod || hasSelectedCard;
    if (!hasInvalidDestination && hasSelectedPaymentMethod) {
      return true;
    }
    return false;
  }
};
