import * as mutation from "../mutations-types";

export default {
  [mutation.UPDATE_DELIVERY_MODE](state, data) {
    state.deliveryMode = data;
  },
  [mutation.UPDATE_DESTINATION](state, destination) {
    state.destinations = state.destinations.map((item, i) => {
      if (destination.index === i) {
        return { ...destination };
      }
      return item;
    });
  },
  [mutation.SET_ADDITIONAL_OPTIONS_SIGNATURE](state, signature) {
    state.additionalOptions.deliveryProof = signature;
  }
};
