import _toNumber from "lodash/toNumber";
import _isNaN from "lodash/isNaN";

export function makeShortUUID(id = ""): string {
  return id ? id.substr(id.length - 6) : "";
}

/**
 * Removes '-' characters from the UUID 'id' provided.
 * @param {string} id A UUID value represented as a string.
 */
export function deflateUUID(id: string) {
  return id.replace(/-/g, "");
}

export function formatPhone(value: string): string {
  if (value == null) return "";
  const x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/) || "";
  return !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
}

export function formatSendPhone(value: string) {
  return value != null ? value.replace(/\(|\)| |-|\+52/g, "") : "";
}

/**
 * Validate that the string has the correct name format.
 * Valid format: Cadena
 * Invalid format: Caden4
 * @param {string} name String to validate.
 */
export function validateName(name: string) {
  const nameRejectRegexp = /\d/;
  return !nameRejectRegexp.test(name) && name.length > 0;
}

/**
 * Validate that string have the correct email format
 * Valid format: cadena@cadena.co,
 */
export function validateEmail(email: string) {
  // eslint-disable-next-line
  const mailRegexp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return mailRegexp.test(String(email));
}

/**
 * Validate that string have the correct phone format
 * Valid format: (33) 3761-8690, 3335976089, +52 3335976089
 */
export function validatePhone(phone: string) {
  // eslint-disable-next-line
  const phoneRegEx = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$$/;
  return phoneRegEx.test(phone) && phone.length >= 10;
}

/**
 * Used in listing to parse the query parameters received in URLs
 * @param {*} val
 * @returns Parsed query parmeter
 */
export function parseQueryParam(val: string) {
  if (val === "" || val === null) return undefined;
  const numVal = _toNumber(val);
  if (!_isNaN(numVal)) {
    return numVal;
  }
  return val;
}
