import Vue from "vue";
import App from "./App.vue";
import _debounce from "lodash/debounce";
import store from "@/store";
import router from "@/router";
import "@/app/components/globals";
import "./plugins";
import i18n from "./plugins/vue-i18n";
import "./index.scss";
import "./registerServiceWorker";

Vue.config.devtools = true;

/* Sync routes with auth module */
store.dispatch("auth/syncRouter", router);
store.dispatch("getCountry");

export default new Vue({
  i18n,
  el: "#root",
  store,
  router,
  beforeCreate: () => store.commit("initialiseStore"),

  created() {
    this.onResize = _debounce(this.onResize, 300);
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    onResize() {
      store.commit("setWindowWidth", window.innerWidth);
    }
  },
  render: h => h(App)
});
