import mutations from "./mutations";
import getters from "./getters";
import state from "./state";
import deliveries from "./deliveries";
import reservations from "./reservations";

export default {
  namespaced: true,
  state: Object.assign({}, state),
  mutations,
  getters,
  modules: {
    deliveries,
    reservations
  }
};
