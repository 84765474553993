export default class Storage {
  constructor(key) {
    this.key = key;
  }

  set(state) {
    localStorage.setItem(this.key, JSON.stringify(state));
  }

  get() {
    // Validate key
    if (localStorage.getItem(this.key)) {
      return localStorage.getItem(this.key);
    }
    return null;
  }

  clean() {
    localStorage.removeItem(this.key);
  }
}
