const defaultState = {
  dateFilters: [],
  defaultAddress: null,
  eventId: 1,
  events: [],
  makingReservation: false,
  selectedEvent: null,
  maxDrivers: 10
};

export default defaultState;
