import ClientType from "@zubut/common/src/constants/clients/type";

const defaultState = {
  create: {
    type: ClientType.NUM_BRANCH,
    companyName: "",
    parentCompany: null,
    businessLine: null,
    contactName: "",
    phone: "",
    email: "",
    password: "zubut",
    address: {
      address: "",
      position: {}
    },
    addressReferences: "",
    defaultAddress: true
  }
};

export default defaultState;
