import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("Deliveries");

const Deliveries = Object.assign(
  {},
  {
    getDetails(id) {
      return loopback.get(`/Deliveries/${id}/details`);
    },

    listing({ filter, where }) {
      return loopback.get("/Deliveries/listing", {
        params: { filter, where }
      });
    },

    logs(id) {
      return loopback.get(`/Deliveries/${id}/logs`);
    },

    history({ filter, where }) {
      return loopback.get("/Deliveries/history", {
        params: { filter, where }
      });
    }
  },
  modelObject
);

export default Deliveries;
