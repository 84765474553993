export default {
  setLoggedUser(user) {
    if (user.account.length > 0) {
      user.billingData = user.account[0].billingData;
      user.accountUser = true;
    } else {
      user.accountUser = false;
    }
  },

  setDefaultOrigin(id) {
    const user = this.getLoggedUserData();
    user.defaultOrigin = id;
    localStorage.setItem("user", JSON.stringify(user));
  },

  getDefaultOrigin() {
    return this.getLoggedUserData().defaultOrigin;
  },

  getLoggedUserId() {
    return this.getLoggedUserData().id;
  },

  getLoggedUserData() {
    return JSON.parse(localStorage.getItem("user"));
  }
};
