import * as mutation from "./mutations-types";
import defaultState from "./state";
import cloneDeep from "lodash/cloneDeep";

export default {
  [mutation.RESET](state) {
    const s = defaultState;
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },

  [mutation.UPDATE_DATE](state, date) {
    if (date.from) state.date.from = date.from;
    if (date.until) state.date.until = date.until;
  },

  [mutation.UPDATE_DATE_UNTIL](state, until) {
    state.date.until = until;
  },

  [mutation.UPDATE_DATE_FROM](state, from) {
    state.date.from = from;
  },

  [mutation.UPDATE_FILTER](state, filter) {
    state.filter = Object.assign({}, filter);
  },

  [mutation.UPDATE_FILTER_DATE](state, date) {
    state.filter.date = date;
  },

  [mutation.UPDATE_FILTER_STATUS](state, status) {
    state.filter.status = status;
  },

  [mutation.UPDATE_FILTER_SERVICE_TYPE](state, serviceType) {
    state.filter.serviceType = serviceType;
  },

  [mutation.UPDATE_FILTER_VEHICLE_TYPE](state, vehicleType) {
    state.filter.vehicleType = vehicleType;
  }
};
