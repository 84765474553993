import { captureError, clearUser, setUser } from "@/plugins/error-tracking";
import Vue from "vue";
import Clients from "@/services/clients";
import CreditCards from "@/services/creditCards";
import { userSession } from "@/storage";
import * as mutation from "./mutations-types";
import Http from "@zubut/common/src/constants/http";
import UserAddresses from "../../../services/userAddresses";
import { formatSendPhone } from "@zubut/common/src/utils/strings";

export function reset({ commit, state }) {
  return new Promise(resolve => {
    commit(mutation.RESET);
    userSession.clean();
    resolve(state);
  });
}

export function loadAccount({ commit }, clientId) {
  return new Promise((resolve, reject) => {
    Clients.findById({
      id: `${clientId}`,
      filter: {
        include: [
          {
            relation: "creditCards"
          },
          {
            relation: "billingData"
          },
          {
            relation: "ratings"
          },
          {
            relation: "wallet"
          },
          {
            relation: "addresses"
          },
          {
            relation: "account",
            scope: {
              include: [
                {
                  relation: "billingData"
                }
              ]
            }
          }
        ]
      }
    })
      .then(res => {
        // Update user logged
        const user = res;

        if (!user.billingData) {
          user.billingData = {
            id: "",
            city: "",
            country: "México",
            numExt: "",
            numInt: "",
            state: "",
            street: "",
            zipcode: "",
            neighborhood: "",
            billingName: "",
            rfc: "",
            alternateEmail1: "",
            alternateEmail2: ""
          };
        }

        setUser({ id: user.id, email: user.email });
        delete user.conektaCustomerId;
        commit(mutation.UPDATE_USER, user);
        delete user.creditCards;
        delete user.wallet;
        delete user.addresses;
        userSession.set(user);
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

/* Credit Cards */
export function getCreditCards({ state, commit }) {
  const clientId = state.id;
  return new Promise((resolve, reject) => {
    Clients.getCreditCards(clientId)
      .then(userCreditCards => {
        if (userCreditCards) {
          commit(mutation.DELETE_ALL_CREDIT_CARDS);
          userCreditCards.forEach(creditCard => {
            commit(mutation.ADD_CREDIT_CARD, creditCard);
          });
          resolve(userCreditCards);
        } else {
          reject();
        }
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

// eslint-disable-next-line
export function deleteCreditCard({ state, commit, dispatch }, creditCard) {
  const clientId = state.id;
  return new Promise((resolve, reject) => {
    const id = creditCard.id;
    const isDefault = creditCard.default;
    Clients.creditCard
      .destroyById({ id: clientId, fk: id })
      .then(res => {
        commit(mutation.DELETE_CREDIT_CARD, id);
        if (
          isDefault &&
          state.creditCards &&
          state.creditCards.length > 0 &&
          state.creditCards[0]
        ) {
          dispatch("setDefaultCreditCard", state.creditCards[0].id);
          commit(mutation.UPDATE_DEFAULT_CREDIT_CARD, state.creditCards[0].id);
        }
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function setDefaultCreditCard({ state, commit }, idCC) {
  const clientId = state.id;
  return new Promise((resolve, reject) => {
    CreditCards.setDefault({ id: idCC, clientId })
      .then(res => {
        // eslint-disable-next-line
        commit(mutation.CLEAR_DEFAULT_CREDIT_CARD);
        commit(mutation.UPDATE_DEFAULT_CREDIT_CARD, idCC);
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function addCreditCard({ state, commit }, tokenParams) {
  return new Promise((resolve, reject) => {
    const successResponseHandler = token => {
      const isFirstCard = state.creditCards && state.creditCards.length === 0;
      const clientId = state.id;
      CreditCards.validate({
        token,
        clientId,
        isFirstCard
      })
        .then(res => {
          const isNewCreditCard = !state.creditCards.find(
            cc => cc.id === res.data.id
          );
          if (isNewCreditCard) {
            commit(mutation.ADD_CREDIT_CARD, res.data);
          }
          resolve({ data: res.data, isNewCreditCard });
        })
        .catch(err => {
          captureError(err);
          reject(err);
        });
    };

    const errorResponseHandler = err => {
      reject(err);
    };

    window.Conekta.Token.create(
      tokenParams,
      successResponseHandler,
      errorResponseHandler
    );
  });
}

// Addresses
export function getFavoriteAddresses({ state, commit }) {
  return new Promise((resolve, reject) => {
    const clientId = state.id;
    Clients.addresses
      .find({ id: clientId, filter: { order: "name" } })
      .then(addresses => {
        if (addresses) {
          commit(mutation.DELETE_ALL_ADDRESS);
          addresses.forEach(address => {
            commit(mutation.ADD_ADDRESS, address);
          });
        }
        resolve(addresses);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function deleteFavoriteAddress({ state, commit }, address) {
  return new Promise((resolve, reject) => {
    const clientId = state.id;
    Clients.addresses
      .destroyById({ id: clientId, fk: address.id })
      .then(res => {
        const index = state.addresses.indexOf(address);
        commit(mutation.DELETE_ADDRESS, index);
        resolve(res, index);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function createOrUpdateFavoriteAddress({ state, commit }, address) {
  return new Promise((resolve, reject) => {
    const clientId = state.id;
    address.phone = formatSendPhone(address.phone);
    if (address.id) {
      // Address already exist, update
      Clients.addresses
        .updateById({ id: clientId, fk: address.id }, address)
        .then(res => {
          const index = state.addresses.findIndex(item => item.id === res.id);
          if (clientId.toString() === address.clientId.toString()) {
            commit(mutation.UPDATE_ADDRESS, { index, address });
          }
          resolve(res);
        })
        .catch(err => {
          captureError(err);
          reject(err);
        });
    } else {
      // Create new address
      Clients.addresses
        .create({ id: clientId }, address)
        .then(res => {
          commit(mutation.ADD_ADDRESS, res);
          resolve(res);
        })
        .catch(err => {
          captureError(err);
          reject(err);
        });
    }
  });
}

export function changeDefaultOrigin(
  { state, commit },
  { addressId, clientId }
) {
  return new Promise((resolve, reject) => {
    UserAddresses.setAddressDefault({
      data: { clientId: clientId, id: addressId }
    })
      .then(address => {
        if (address) {
          commit(mutation.CLEAR_DEFAULT_ADDRESS);
          const index = state.addresses.findIndex(
            innerAddress => innerAddress.id === address.id
          );
          commit(mutation.UPDATE_DEFAULT_ADDRESS, index);
          resolve(addressId);
        } else {
          reject();
        }
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function clearDefaultOrigin({ state, commit }) {
  return new Promise(resolve => {
    const addressId = state.default_address.id;
    commit(mutation.CLEAR_DEFAULT_ADDRESS);
    resolve(addressId);
  });
}

// wallet
export function getWallet({ state, commit }) {
  return new Promise((resolve, reject) => {
    const clientId = state.id;
    Clients.find({
      id: clientId,
      filter: {
        include: [
          {
            relation: "wallet"
          }
        ]
      }
    })
      .then(res => {
        if (res && res.wallet) {
          commit(mutation.UPDATE_WALLET, res.wallet);
          resolve(res.wallet);
        } else {
          resolve();
        }
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function deleteWalletPeding({ commit }) {
  return new Promise(resolve => {
    commit(mutation.DELETE_WALLET_PENDING);
    resolve();
  });
}

// Invoices
export function updateBillingInfo({ state, commit }, billingData) {
  return new Promise((resolve, reject) => {
    Clients.billingData
      .update({ id: state.id }, billingData)
      .then(res => {
        commit(mutation.UPDATE_BILLING_DATA, res);
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function getActualPosition({ commit }) {
  const getPosition = options => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        position => {
          const storageUser = JSON.parse(userSession.get());
          const newPosition = {
            coords: {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            },
            accuracy: 1000 * 30
          };
          storageUser.sessionPosition = newPosition;
          userSession.set(storageUser);
          commit(mutation.UPDATE_SESSION_POSITION, newPosition);
          resolve();
        },
        err => {
          captureError(err);
          reject(err);
        }
      );
    });
  };

  return getPosition().catch(err => {
    console.log("error", err);
  });
}

export function passwordRecovery({}, email) {
  return new Promise((resolve, reject) => {
    Clients.emailRecovery({ email })
      .then(response => {
        if (response.data.status === 404) {
          reject(new Error("Usuario no localizado"));
        }
        if (response.data.status === 200) {
          resolve(response);
        }
        reject(new Error(response));
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function updatePasswordRecovery({}, { secret, pass }) {
  return new Promise((resolve, reject) => {
    Clients.passwordRecovery(secret, pass)
      .then(() => {
        resolve();
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function confirmAccount({}, id) {
  return new Promise((resolve, reject) => {
    Clients.userActivation({
      clientId: id
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function updateUser({ commit }, { id, data }) {
  return new Promise((resolve, reject) => {
    Clients.editUser(id, data)
      .then(res => {
        if (res) {
          // Save new profile data in localstorage
          let storageUser = JSON.parse(userSession.get());
          storageUser = res;
          userSession.set(storageUser);

          // Update vuex store
          commit(mutation.UPDATE_USER, res);
        }
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        if (err.statusCode === Http.UNPROCESSABLE_ENTITY) {
          reject(new Error("Correo electrónico ya se encuentra registrado"));
        } else {
          reject(err);
        }
      });
  });
}

export function updateBillingData({ commit }, { clientId, data }) {
  return new Promise((resolve, reject) => {
    Clients.billingData
      .update({ id: clientId }, data)
      .then(res => {
        // Save billing data in local storage
        const storageUser = JSON.parse(userSession.get());
        storageUser.billingData = res;
        userSession.set(storageUser);

        // Save billing data in vuex
        commit(mutation.UPDATE_BILLING_DATA, res);

        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function createBillingData({ commit }, { clientId, data }) {
  return new Promise((resolve, reject) => {
    Clients.billingData
      .create({ id: clientId }, data)
      .then(res => {
        // Save billing data in local storage
        const storageUser = JSON.parse(userSession.get());
        storageUser.billingData = res;
        userSession.set(storageUser);

        // Save billing data in vuex
        commit(mutation.UPDATE_BILLING_DATA, res);

        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function dispatchBillingData(
  { dispatch },
  { clientId, data, billingDataClientId }
) {
  return new Promise((resolve, reject) => {
    if (data.id && clientId === billingDataClientId) {
      data.updatedAt = new Date();
      dispatch("updateBillingData", { clientId, data })
        .then(res => resolve(res))
        .catch(err => reject(err));
    } else {
      delete data.id;
      dispatch("createBillingData", { clientId, data })
        .then(res => resolve(res))
        .catch(err => reject(err));
    }
  });
}

export function dispatchUpdatePassword(
  { dispatch },
  { currentPassword, newPassword }
) {
  const data = {
    oldPassword: currentPassword,
    newPassword,
    newPasswordVerification: newPassword
  };
  return new Promise((resolve, reject) => {
    Clients.changePassword(data)
      .then(() => {
        dispatch("renewSession")
          .then(() => {
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function renewSession({ dispatch, commit }) {
  return new Promise((resolve, reject) => {
    dispatch("auth/logout", {}, { root: true })
      .then(() => {
        clearUser();
        commit("reset");
        commit("auth/reset", {}, { root: true });
        commit("rents/reset", {}, { root: true });
        dispatch("services/reset", {}, { root: true });
        commit("request/reset", {}, { root: true });
        dispatch("reset");
        resolve();
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function updateAdditional({ commit }, { clientId, data }) {
  return new Promise((resolve, reject) => {
    Clients.updateAdditional(clientId, data)
      .then(res => {
        const additional = res.additional;
        const storageUser = JSON.parse(userSession.get());
        storageUser.additional = additional;
        userSession.set(storageUser);
        commit(mutation.UPDATE_USER_ADDITIONAL, additional);
        resolve(res);
      })
      .catch(err => {
        captureError(err);
        reject(err);
      });
  });
}

export function enableSPEI({ commit }) {
  return new Promise((resolve, reject) => {
    Clients.enableSPEI()
      .then(res => {
        if (res.amount) {
          res.amount /= 100;
        }
        commit(mutation.UPDATE_WALLET, res);
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function updateEnabledServices({ commit }, enabledServices) {
  return new Promise((resolve, reject) => {
    Clients.edit({
      enabledServices: [...enabledServices]
    })
      .then(res => {
        commit(mutation.UPDATE_ENABLED_SERVICES, res.enabledServices);
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function loadConekta() {
  return new Promise((resolve, reject) => {
    if (!window.Conekta) {
      Vue.loadScript("https://cdn.conekta.io/js/latest/conekta.js")
        .then(() => {
          window.Conekta.setPublicKey(`${process.env.VUE_APP_CONEKTA_KEY}`);
          resolve(window.Conekta);
        })
        .catch(err => {
          reject(err);
        });
    } else {
      resolve(window.Conekta);
    }
  });
}
