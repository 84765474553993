const SAME_DAY = "Same day";
const NEXT_DAY = "Next day";
const UNSUCCESSFUL_DELIVERY = "Entrega no exitosa";
const RETURN = "Regreso";

const NUM_SAME_DAY = 0;
const NUM_NEXT_DAY = 1;
const NUM_UNSUCCESSFUL_DELIVERY = 2;
const NUM_RETURN = 3;

const get = {};
get[NUM_SAME_DAY] = SAME_DAY;
get[NUM_NEXT_DAY] = NEXT_DAY;
get[NUM_UNSUCCESSFUL_DELIVERY] = UNSUCCESSFUL_DELIVERY;
get[NUM_RETURN] = RETURN;

const options = Object.keys(get).map(key => ({
  text: get[key],
  value: parseInt(key)
}));

export default {
  get,
  options,
  NUM_SAME_DAY,
  NUM_NEXT_DAY,
  NUM_UNSUCCESSFUL_DELIVERY,
  NUM_RETURN,
  SAME_DAY,
  NEXT_DAY,
  UNSUCCESSFUL_DELIVERY,
  RETURN
};
