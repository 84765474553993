const defaultState = {
  account: [],
  additional: {},
  addresses: [],
  creditCards: [],
  enabledServices: [],
  billingData: null,
  wallet: {
    id: "",
    pending: false,
    isWallet: true,
    brand: "Wallet",
    amount: 0,
    clabeReferente: ""
  },
  createdAt: "",
  updatedAt: "",
  lastLogin: "",
  email: "",
  id: "",
  distinguished: false,
  status: 0,
  type: 0,
  userType: 4,
  zoneBoundaries: false,
  sessionPosition: null,
  name: "",
  phone: ""
};

export default defaultState;
