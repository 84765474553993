import i18n from "@/plugins/vue-i18n";

type CountryCode = "CO" | "MX";

function changeFavicon() {
  const favicon16 = document.querySelector(
    'link[href="/img/icons/favicon-16x16.png"]'
  ) as HTMLLinkElement;
  const favicon32 = document.querySelector(
    'link[href="/img/icons/favicon-32x32.png"]'
  ) as HTMLLinkElement;

  if (favicon16) {
    favicon16.href = "/img/icons/favicon-16x16-mu.png";
  }

  if (favicon32) {
    favicon32.href = "/img/icons/favicon-32x32-mu.png";
  }
}

function setNewColorPalette() {
  const element = document.getElementsByTagName("body")[0];
  // Overwrite css color vars
  // Main colors
  element.style.setProperty("--primary", "#09aac0");
  element.style.setProperty("--primary-bg", "#e3f6f8");

  // Button styles
  element.style.setProperty("--btn-primary-hover", "#108fa0");
  element.style.setProperty("--btn-primary-disabled", "#aadde4");

  // Delivery type badges styles
  element.style.setProperty("--delivery-type-primary-bg", "#e3f6f8");
  element.style.setProperty("--delivery-type-primary-disabled", "#aadde4");

  // Calendar styles
  element.style.setProperty("--event-primary-bg", "#0889A0");
}

export default function changeTheme(countryCode: CountryCode) {
  if (countryCode === "CO") {
    setNewColorPalette();

    changeFavicon();

    // Change title
    document.title = "Mensajeros Urbanos";

    // Set locale
    i18n.locale = "es-CO";
  } else {
    i18n.locale = "es-MX";
  }
}
