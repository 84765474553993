import * as mutation from "./mutations-types";
import defaultState from "./state";

export default {
  [mutation.RESET](state) {
    const s = defaultState;
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },

  // Destination creation
  [mutation.UPDATE_DESTINATION](state, destination) {
    state.destinations = state.destinations.map((item, i) => {
      if (destination.index === i) {
        return { ...destination };
      }
      return item;
    });
  },

  [mutation.UPDATE_MEASURES](state, data) {
    state.measures = data;
  },

  [mutation.UPDATE_DELIVERY_MODE](state, data) {
    state.deliveryMode = data;
  },

  [mutation.SET_ADDITIONAL_OPTIONS_EXTERNAL_ID](state, externalId) {
    state.additionalOptions.externalId = externalId;
  },

  [mutation.SET_ADDITIONAL_OPTIONS_SIGNATURE](state, signature) {
    state.additionalOptions.signature = signature;
  }
};
